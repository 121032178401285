import React, { useState, useEffect, useRef } from 'react';
import { getToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import th from "date-fns/locale/th";
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';

const host = 'https://api.dataxo.info';

function InputDoc() {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (!token) {
            navigate('/signin');
            return;
        }
    }, [])

    const userId = localStorage.getItem('userId');
    const [clientIP, setClientIP] = useState('');
    useEffect(() => {
        //service for get  client IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setClientIP(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    const [startDate1, setStartDate1] = useState(null, null);
    const [startDate2, setStartDate2] = useState(null, null);
    const [startDate3, setStartDate3] = useState(null, null);

    const [inputdoc_get_no, setInputdoc_get_no] = useState('');
    const [inputdoc_get_y, setInputdoc_get_y] = useState('');
    const [inputdoc_no, setInputdoc_no] = useState('');
    const [inputdoc_date, setInputdoc_date] = useState('');
    const [office_name, setOffice_name] = useState('');
    const [year_filter, setYear_filter] = useState('');
    const [groupdoc, setGroupdoc] = useState('');

    const officeref = useRef(null);
    const groupyref = useRef(null);
    const groupref = useRef(null);

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if (isChecked) {
            //clear group input
            setGroupdoc('');
        }
    }

    const [dataOffice, setDataOffice] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/office`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataOffice(json));
    }, []);

    let [yearDocParam, setYearDocParam] = useState(null);
    const [dataInputdoc, setDataInputdoc] = useState([]);
    useEffect(() => {
        let y = yearDocParam;
        if (y === '') { y = null }
        const inputdocurl = `${host}/api-data/indoc/get/${y}`;
        fetch(inputdocurl, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataInputdoc(json));
    }, [yearDocParam]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${host}/api-input/insindoc`;
        const formData = {
            inputdoc_get_no,
            inputdoc_get_y,
            inputdoc_no,
            inputdoc_date,
            office_name,
            groupdoc,
            clientIP,
            userId
        }
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then((json, modal) => {
                if (json === 'success') {
                    openModal('บันทึกข้อมูลเอกสารเรียบร้อยแล้ว', modal = 1);
                }
                if (json === 'exist') {
                    openModal('ไม่มีการบันทึก เนื่องจากมีข้อมูลเอกสารนี้อยู่แล้ว', modal = 2);
                }
            });
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModal = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        } else {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalOpen} onExit={closeModal} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModal} className="btn bg-transparents"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                </div>
            </Modal>
        );
    }

    return (
        <div className="align-items-center justify-content-center w-100 p-2">
            <form onSubmit={handleSubmit} method="post" autoComplete="off">
                <div className="h5 p-2 bg-primary rounded-3">หนังสือรับเข้า</div>
                <div className="input-group m-3">
                    <div className="form-floating">
                        <input id="inputdoc_get_no" name="inputdoc_get_no" value={inputdoc_get_no} onChange={(e) => setInputdoc_get_no(e.target.value)} onBlur={(e) => setInputdoc_get_no(e.target.value.trim())} type="text" className="form-control border-2" maxLength="45"
                            required />
                        <label htmlFor="inputdoc_get_no" className="text-light">เลขรับ</label>
                    </div>
                    <div className="form-floating mx-3">
                        <DatePicker
                            selected={startDate1}
                            onChange={
                                (date) => {
                                    if (date != null) {
                                        date = format(date, 'yyyy');
                                    }
                                    setStartDate1(date);
                                    setInputdoc_get_y(date);
                                }
                            }
                            showYearPicker
                            yearItemNumber={3}
                            dateFormat="yyyy"
                            locale={th}
                            todayButton="ปีนี้"
                            id="inputdoc_get_y" name="inputdoc_get_y" value={inputdoc_get_y} type="text" className="form-control pt-4 border-2" maxLength="4" required />
                        <label htmlFor="inputdoc_get_y" className="text-light p-1 ps-2 small">ปี</label>
                    </div>
                </div>
                <div className="input-group mx-3">
                    <div className="form-floating me-3">
                        <input id="inputdoc_no" name="inputdoc_no" value={inputdoc_no}
                            onChange={(e) => setInputdoc_no(e.target.value)}
                            onBlur={(e) => setInputdoc_no(e.target.value.trim())}
                            type="text" className="form-control border-2" maxLength="45" required />
                        <label htmlFor="inputdoc_no" className="text-light">เลขหนังสือ</label>
                    </div>
                    <div className="form-floating me-3">
                        <DatePicker
                            selected={startDate2}
                            onChange={(date) => {
                                if (date != null) {
                                    date = format(date, 'yyyy-MM-dd');
                                }
                                setStartDate2(date);
                                setInputdoc_date(date);
                            }}
                            showDatePicker
                            dateFormat="yyyy-MM-dd"
                            locale={th}
                            todayButton="วันนี้"
                            id="inputdoc_date" name="inputdoc_date" value={inputdoc_date} type="text" className="form-control pt-4 border-2" required />
                        <label htmlFor="inputdoc_date" className="text-light p-1 ps-2 small">ลงวันที่</label>
                    </div>
                </div>
                <div className="mx-3">
                    <div className="form-floating">
                        <Typeahead
                            ref={officeref}
                            onChange={(selected) => setOffice_name(selected)}
                            onBlur={(e) => setOffice_name(e.target.value.trim())}
                            options={dataOffice.map((dataOffice, index) => (dataOffice.office_name))}
                            highlightOnlyResult
                            emptyLabel="ไม่เคยมีในฐานข้อมูล"
                            paginationText="ต่อไป..."
                            inputProps={{ required: true, className: "form-control mt-3 border-2" }}
                            id="office_name" name="office_name" value={office_name} type="text" style={{ height: '80px' }}
                            maxLength="100" />
                        <label htmlFor="office_name" className="text-light small">จากหน่วยงาน/บุคคล</label>
                    </div>
                </div>
                <div className="m-3">
                    <input id="groupcheckbox" name="groupcheckbox" type="checkbox" className="form-check-input"
                        checked={isChecked}
                        onChange={handleCheckboxChange} />
                    <label className="form-check-label mx-2" htmlFor="groupcheckbox">
                        มีกลุ่มเอกสาร
                    </label>
                </div>
                <div className="m-3">
                    {isChecked && (
                        <div id="addgroup" className="ms-5">
                            <div className="form-floating" style={{ zIndex: '3' }}>
                                <DatePicker
                                    ref={groupyref}
                                    selected={startDate3}
                                    onChange={(date) => {
                                        if (date != null) {
                                            date = format(date, 'yyyy');
                                        }
                                        setStartDate3(date);
                                        setYear_filter(date);
                                        groupref.current.clear();
                                    }}
                                    showYearPicker
                                    yearItemNumber={3}
                                    dateFormat="yyyy"
                                    locale={th}
                                    todayButton="ปีนี้"
                                    id="year_filter" value={year_filter} type="text" className="form-control pt-4 border-2" maxLength="4" />
                                <label htmlFor="year_filter" className="text-light p-1 ps-2 small">ปี</label>
                            </div>
                            <div className="form-floating">
                                <Typeahead
                                    ref={groupref}
                                    onFocus={() => setYearDocParam(groupyref.current.props.value)}
                                    onChange={(selected) => setGroupdoc(selected)}
                                    onBlur={(e) => setGroupdoc(e.target.value.trim())}
                                    options={dataInputdoc.map((dataInputdoc, index) => ('เลขที่ ' + dataInputdoc.inputdoc_no + ' วันที่ ' + new Date(dataInputdoc.inputdoc_date).toLocaleDateString('th-TH') + ' เลขรับ ' + dataInputdoc.inputdoc_get_no))}
                                    highlightOnlyResult
                                    emptyLabel="ไม่เคยมีในปีที่เลือก"
                                    paginationText="ต่อไป..."
                                    id="groupdoc" name="groupdoc" value={groupdoc} type="text" style={{ height: '80px' }}
                                    maxLength="45" inputProps={{ className: "form-control mt-3 border-2", required: isChecked }} />
                                <label htmlFor="group_no" className="text-light small">เลขรับ/เลขหนังสือ</label>
                            </div>
                        </div>
                    )}
                </div>
                <button className="btn btn-primary py-2 ms-5 m-3" type="submit">บันทึก</button>
            </form >
            <>
                <ModalInfo />
            </>
        </div >
    )
}

export default InputDoc;