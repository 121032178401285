function AppFooter() {
    return (
        <div className='AppFooter'>
            <footer className="py-3 my-4">
                <ul className="nav justify-content-center border-bottom pb-3 mb-3" />
                <p className="text-center text-body-secondary">© 2024-2025 JaRan LaoThong</p>
            </footer>
        </div>
    )
}

export default AppFooter;
