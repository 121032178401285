import React, { useState, useEffect, useRef, Fragment } from 'react';
import { getToken, removeToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import th from "date-fns/locale/th";
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

registerLocale('th', th);
const host = 'https://api.dataxo.info';

function EditOutDoc(id) {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (!token) {
            navigate('/signin');
            return;
        }
    }, [])

    const handleUnauthResponse = (response) => {
        if (response && (response.status === 401 || response.status === 403)) {
            removeToken();
            navigate('/signin');
            return;
        } else {
            window.location.reload();
        }
    }

    const userId = localStorage.getItem('userId');
    const [clientIP, setClientIP] = useState('');
    useEffect(() => {
        //service for get  client IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setClientIP(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(null, null);
    const [outputdoc_no, setOutputdoc_no] = useState('');
    const [outputdoc_date, setOutputdoc_date] = useState('');
    const [office_name, setOffice_name] = useState('');
    const [officer_name, setOfficer_name] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [sendDate, setSendDate] = useState('');

    const officeref = useRef(null);
    const officerref = useRef(null);
    const emailref = useRef(null);
    const addressref = useRef(null);

    const [inputdoc_no, setInputdoc_no] = useState('');
    const [inputdoc_date, setInputdoc_date] = useState('');
    const [inputdoc_get_no, setInputdoc_get_no] = useState('');

    //get data by document id parameter
    const [editId, setEditId] = useState(id.param);
    const [originalOfficeId, setOriginalOfficeId] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (editId != null) {
                const url = `${host}/api-data/outdoc/send/${editId}`;
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        data.map((data) => (
                            setOutputdoc_no(data.outputdoc_no),
                            setOutputdoc_date(format(data.outputdoc_date, 'yyyy-MM-dd')),
                            setInputdoc_no(data.inputdoc_no),
                            setInputdoc_date(data.inputdoc_date),
                            setInputdoc_get_no(data.inputdoc_get_no),
                            setOriginalOfficeId(data.office_id),
                            setOffice_name(data.office_name),
                            setOfficer_name(data.officer_name || ''),
                            setEmail(data.email || ''),
                            setAddress(data.address || ''),
                            setSendDate(data.senddoc_send_date || '')
                        ))
                    } else {
                        console.error("Failed to fetch data:", response.statusText);
                        handleUnauthResponse(response);
                        throw new Error('Network response was not ok');
                    }
                } catch (err) {
                    console.error("Error fetching data:", err);
                    handleUnauthResponse(err.response);
                } finally {
                    setIsLoading(false);
                }
            }
        }
        fetchData();
    }, [editId]);

    const [dataOffice, setDataOffice] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/office`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataOffice(json));
    }, []);

    const [dataOfficer, setDataOfficer] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/officer`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataOfficer(json));
    }, []);

    const [dataEmail, setDataEmail] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/email`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataEmail(json));
    }, []);

    const [dataAddress, setDataAddress] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/address`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataAddress(json));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${host}/api-input/updateoutdoc/${editId}/${originalOfficeId}`;
        const formData = {
            outputdoc_no,
            outputdoc_date,
            office_name,
            officer_name,
            email,
            address,
            userId,
            clientIP
        }
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then((json, modal) => {
                if (json === 'success') {
                    openModalInfo('บันทึกข้อมูลเอกสารเรียบร้อยแล้ว', modal = 1);
                }
                if (json === 'unchanged') {
                    openModalInfo('ไม่มีการบันทึก เนื่องจากไม่มีการเปลี่ยนแปลง', modal = 2);
                }
                if (json === 'exist') {
                    openModalInfo('ไม่มีการบันทึก เนื่องจากมีข้อมูลเอกสารนี้อยู่แล้ว', modal = 2);
                }
                if (json === 'syserror') {
                    openModalInfo('ไม่มีการบันทึก เนื่องจากเกิดปัญหาจากระบบ', modal = 2);
                }
            });
    }

    const [isDelOutdoc, setIsDelOutdoc] = useState(false);
    useEffect(() => {
        if (isDelOutdoc) {
            const id = editId;
            const url = `${host}/api-input/outdoc/del/${id}`;
            const formData = { clientIP, userId }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                body: JSON.stringify(formData)
            })
                .then(res => res.json())
                .then((json, modal) => {
                    setIsModalDelOpen(false);
                    if (json === 'deleted') {
                        openModalInfo('ลบเอกสารเรียบร้อยแล้ว', modal = 1);
                    }
                    if (json === 'delete-err') {
                        openModalInfo('ไม่สามารถลบได้', modal = 2);
                    }
                });
        }
    }, [isDelOutdoc, editId]);

    const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
    const [isModalDelOpen, setIsModalDelOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModalInfo = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalInfoOpen(true);
    }

    const closeModalInfo = () => {
        setIsModalInfoOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        }
        if (modalType === 2) {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalInfoOpen} onExit={closeModalInfo} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModalInfo} className="btn bg-transparents">
                        <i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i>
                    </button>
                </div>
            </Modal>
        );
    }

    const openModalDel = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalDelOpen(true);
    }

    const closeModalDel = () => {
        setIsModalDelOpen(false);
    }

    const ModalDel = () => {
        return (
            <Modal show={isModalDelOpen} onExit={closeModalDel} size="sm" centered>
                <div className='modal-content align-items-center justify-content-center border-1 border-danger'>
                    <p className="fs-5 p-3">{modalText}</p>
                    <div className="d-inline">
                        <button onClick={() => setIsDelOutdoc(true)} className="btn btn-outline-light py-2 ms-5 m-3">
                            <i className="bi bi-trash3" style={{ fontSize: "1.2rem", color: "#FF4466" }}></i> ลบ
                        </button>
                        <button onClick={closeModalDel} className="btn btn-success py-2 ms-5 m-3">ยกเลิก</button>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <React.Fragment>
            {isLoading ? <div>กำลังโหลดข้อมูล...</div>
                :
                <div className="align-items-center justify-content-center w-100 p-2">
                    <form onSubmit={handleSubmit} method="post" autoComplete="off">
                        <div className="h5 p-2 bg-warning text-dark rounded-3">หนังสือส่งออก</div>
                        <div className="text-light m-3">
                            หนังสือรับเข้า {inputdoc_no} ลงวันที่ {new Date(inputdoc_date).toLocaleDateString('th-TH')}    เลขรับ {inputdoc_get_no}
                        </div>
                        <div className="input-group mx-3">
                            <div className="form-floating me-3">
                                <input id="outputdoc_no" name="outputdoc_no" defaultValue={outputdoc_no}
                                    onChange={(e) => setOutputdoc_no(e.target.value)}
                                    onBlur={(e) => setOutputdoc_no(e.target.value.trim())}
                                    type="text" className="form-control border-2" maxLength="45" required />
                                <label htmlFor="outputdoc_no" className="text-light">เลขหนังสือ</label>
                            </div>
                            <div className="form-floating me-3">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        if (date != null) {
                                            date = format(date, 'yyyy-MM-dd');
                                        }
                                        setStartDate(date);
                                        setOutputdoc_date(date);
                                    }}
                                    showDatePicker
                                    dateFormat="yyyy-MM-dd"
                                    locale={th}
                                    todayButton="วันนี้"
                                    id="outputdoc_date" name="outputdoc_date" value={outputdoc_date}
                                    type="text" className="form-control pt-4 border-2" required />
                                <label htmlFor="outputdoc_date" className="text-light p-1 ps-2 small">ลงวันที่</label>
                            </div>
                        </div>
                        <div className="mx-3">
                            <div className="form-floating">
                                <Typeahead
                                    ref={officeref}
                                    defaultInputValue={office_name.toString()}
                                    onChange={(selected) => setOffice_name(selected)}
                                    onBlur={(e) => setOffice_name(e.target.value.trim())}
                                    options={dataOffice.map((dataOffice, index) => (dataOffice.office_name))}
                                    highlightOnlyResult
                                    emptyLabel="ไม่เคยมีในฐานข้อมูล"
                                    paginationText="ต่อไป..."
                                    inputProps={{ required: true, className: "form-control mt-3 border-2" }}
                                    id="office_name" name="office_name" value={office_name} type="text" style={{ height: '80px' }}
                                    maxLength="100" />
                                <label htmlFor="office_name" className="text-light small">ส่งหน่วยงาน/บุคคล</label>
                            </div>
                        </div>
                        <div className="mx-3">
                            <div className="form-floating">
                                <Typeahead
                                    ref={officerref}
                                    defaultInputValue={officer_name.toString()}
                                    onChange={(selected) => setOfficer_name(selected)}
                                    onBlur={(e) => setOfficer_name(e.target.value.trim())}
                                    options={dataOfficer.map((dataOfficer, index) => (dataOfficer.officer_name))}
                                    highlightOnlyResult
                                    emptyLabel="ไม่เคยมีในฐานข้อมูล"
                                    paginationText="ต่อไป..."
                                    inputProps={{ required: false, className: "form-control mt-3 border-2 w-50" }}
                                    id="officer_name" name="officer_name" value={officer_name} type="text" style={{ height: '80px' }}
                                    maxLength="45" />
                                <label htmlFor="officer_name" className="text-light small">ชื่อเจ้าหน้าที่</label>
                            </div>
                        </div>
                        <div className="mx-3">
                            <div className="form-floating">
                                <Typeahead
                                    ref={emailref}
                                    defaultInputValue={email.toString()}
                                    disabled={sendDate ? true : false}
                                    onChange={(selected) => setEmail(selected)}
                                    onBlur={(e) => setEmail(e.target.value.trim())}
                                    options={dataEmail.map((dataEmail, index) => (dataEmail.email))}
                                    highlightOnlyResult
                                    emptyLabel="ไม่เคยมีในฐานข้อมูล"
                                    paginationText="ต่อไป..."
                                    inputProps={{ required: false, className: "form-control mt-3 border-2 w-75" }}
                                    id="email" name="email" value={email} type="text" style={{ height: '80px' }}
                                    maxLength="200" />
                                <label htmlFor="email" className="text-light small">อีเมล</label>
                            </div>
                        </div>
                        <div className="mx-3">
                            <div className="form-floating">
                                <Typeahead
                                    ref={addressref}
                                    defaultInputValue={address.toString()}
                                    onChange={(selected) => setAddress(selected)}
                                    onBlur={(e) => setAddress(e.target.value.trim())}
                                    options={dataAddress.map((dataAddress, index) => (dataAddress.address))}
                                    highlightOnlyResult
                                    emptyLabel="ไม่เคยมีในฐานข้อมูล"
                                    paginationText="ต่อไป..."
                                    inputProps={{ required: false, className: 'form-control mt-3 border-2' }}
                                    id="address" name="address" value={address} type="text" style={{ height: '80px' }}
                                    maxLength="200" />
                                <label htmlFor="address" className="text-light small">ที่อยู่</label>
                            </div>
                        </div>
                        <button className="btn btn-success py-2 ms-5 mt-3 ms-3" type="submit">บันทึกแก้ไข</button>
                    </form >
                    <div className="d-flex flex-column align-items-end">
                        <OverlayTrigger
                            placement="left"
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    ลบเอกสารนี้
                                </Tooltip>
                            )}>
                            <button type="button" onClick={() => openModalDel('ลบเอกสารออกจากฐานข้อมูลหรือไม่?')}
                                className="btn bg-transparents align-items-end"><i
                                    className="bi bi-trash3" style={{ fontSize: "1.2rem", color: "#FFFFFF" }}></i>
                            </button>
                        </OverlayTrigger>
                    </div>
                    <>
                        <ModalInfo />
                    </>
                    <>
                        <ModalDel />
                    </>
                </div >
            }
        </React.Fragment>
    )
}

export default EditOutDoc;