import homePNG from './custom-assets/home.png';

function Page404() {
    return (
        <div className="Page404">
            <div className=" text-secondary d-flex align-items-center justify-content-center"
                style={{ fontSize: '80px' }}>
                <div className="border border-0 rounded p-2">
                    <img src={homePNG} className="img-fluid d-block rounded-4 mx-auto" style={{width:'60%'}}></img>
                </div>
            </div>
        </div>
    )
}

export default Page404;