import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { PieChart, Pie } from 'recharts';
import { AreaChart, Area } from 'recharts';
import { BarChart, Bar, Cell } from 'recharts';
import { getToken, removeToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';
import Page404 from './404.jsx'
const host = 'https://api.dataxo.info';

function DashB() {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    const [dataInDateCount, setDataInDateCount] = useState([]);
    const [dataInCount, setDataInCount] = useState([]);
    const [dataOutCount, setDataOutCount] = useState([]);
    const [dataSentCount, setDataSentCount] = useState([]);
    const [dataNotSendCount, setDataNotSendCount] = useState([]);
    const [inOutCount, setInOutCount] = useState([]);
    const [sendCount, setSendCount] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const COLORS = []; //array to store random colors
    //generate random colors
    for (let i = 0; i < 50; i++) {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        COLORS.push(`rgb(${r}, ${g}, ${b})`);
    }

    function getRandomColor() {
        const randomIndex = Math.floor(Math.random() * COLORS.length);
        return COLORS[randomIndex];
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${host}/api-dash/doc/dash/indatecount`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    }
                );
                if (!response.ok) {
                    switch (response.status) {
                        case 403:
                            setError('คุณไม่ได้รับสิทธิ์เข้าถึงข้อมูลนี้');
                            break;
                        case 429:
                            setError(`มีการดึงข้อมูลเกินกำหนด โปรดรอแล้วลองใหม่อีกครั้ง`);
                            break;
                        default:
                            setError('เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง');
                    }
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDataInDateCount(data);
            } catch (err) {
                console.error('Error fetching data:', err);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${host}/api-dash/doc/dash/incount`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDataInCount(data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${host}/api-dash/doc/dash/outcount`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDataOutCount(data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${host}/api-dash/doc/dash/sentcount`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDataSentCount(data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${host}/api-dash/doc/dash/notsendcount`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDataNotSendCount(data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setInOutCount([...dataInCount, ...dataOutCount])
    }, [dataInCount, dataOutCount])

    useEffect(() => {
        setSendCount([...dataNotSendCount, ...dataSentCount])
    }, [dataNotSendCount, dataSentCount])

    const CustomToolTipXY = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-secondary border border-1 rounded-2 p-1">
                    <p className="label">{`${new Date(label).toLocaleDateString('th-TH')} : ${payload[0].value} เรื่อง`}</p>
                </div>
            );
        }
        return null;
    };

    const CustomToolTipPieInOut = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-secondary border border-1 rounded-2 p-1">
                    <p className="label">{`${label != null ? { label } : ' '} ${payload[0].value} เรื่อง`}</p>
                </div>
            );
        }
        return null;
    };

    const getPath = (x, y, width, height) => {
        return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
        Z`;
    };

    const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;
        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
    };

    return (
        <React.Fragment>
            {isLoading ? (
                <div>กำลังโหลดข้อมูล...</div>
            ) : error ? (
                <div className="text-warning">{error}<Page404 /></div>
            ) : (
                <React.Fragment>
                    <div>
                        <i className="bi bi-house-door" style={{ fontSize: "1.7rem", color: "#777777" }}></i>
                    </div>
                    <div className="border border-3 border-secondary rounded-4 p-2 m-3">
                        <h6 className="text-warning">หนังสือรับเข้า</h6>
                        <ResponsiveContainer width={"100%"} height={window.innerWidth > 768 ? 400 : 100}>
                            <BarChart data={dataInDateCount} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                <CartesianGrid strokeDasharray="0.5 5" />
                                <XAxis dataKey="inputdoc_date" tickFormatter={(date) => new Date(date).toLocaleDateString('th-TH')} />
                                <YAxis allowDecimals={false} />
                                <Tooltip content={<CustomToolTipXY />} labelFormatter={(date) => new Date(date).toLocaleDateString('th-TH')} />
                                <Bar dataKey="count" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                                    {dataInDateCount.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % 20]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="border border-3 border-secondary rounded-4 p-2 m-3">
                        <h6 className="text-warning">ปริมาณหนังสือรับเข้า/ออก/ส่งอีเมล</h6>
                        <ResponsiveContainer width={"100%"} height={window.innerWidth > 768 ? 400 : 100}>
                            <PieChart margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <Pie
                                    dataKey="count"
                                    data={dataInCount}
                                    isAnimationActive={false}
                                    cx="40%"
                                    cy="50%"
                                    outerRadius={'70%'}
                                    fill={getRandomColor()}
                                    label
                                />
                                <Pie
                                    dataKey="count"
                                    data={inOutCount}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={'70%'}
                                    fill={getRandomColor()}
                                    label
                                    paddingAngle={5}
                                >
                                    {inOutCount.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ strokeWidth: index * 3 }} />)}
                                </Pie>
                                <Pie
                                    dataKey="count"
                                    data={sendCount}
                                    cx="75%"
                                    cy="50%"
                                    innerRadius={'20%'}
                                    outerRadius={'70%'}
                                    fill="#8884d8"
                                    label
                                    paddingAngle={5}
                                >
                                    {sendCount.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ strokeWidth: index * 3 }} />)}
                                </Pie>
                                <Tooltip content={<CustomToolTipPieInOut />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="border border-3 border-secondary rounded-4 p-2 m-3">
                        <h6 className="text-warning">หนังสือรับเข้า</h6>
                        <ResponsiveContainer width={"100%"} height={window.innerWidth > 768 ? 400 : 100}>
                            <AreaChart data={dataInDateCount}
                                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="inputdoc_date" tickFormatter={(date) => new Date(date).toLocaleDateString('th-TH')} />
                                <YAxis allowDecimals={false} />
                                <CartesianGrid strokeDasharray="0.5 5" />
                                <Tooltip content={<CustomToolTipXY labelFormatter={(date) => new Date(date).toLocaleDateString('th-TH')} />} />
                                <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="border border-3 border-secondary rounded-4 p-2 m-3">
                        <h6 className="text-warning">หนังสือรับเข้า</h6>
                        <ResponsiveContainer width={"100%"} height={window.innerWidth > 768 ? 400 : 100}>
                            <LineChart
                                width={775}
                                height={250}
                                data={dataInDateCount}
                                label
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="0.5 5" />
                                <XAxis dataKey="inputdoc_date" tickFormatter={(date) => new Date(date).toLocaleDateString('th-TH')} />
                                <YAxis allowDecimals={false} />
                                <Tooltip content={<CustomToolTipXY />} />
                                <Line type="monotone" dataKey="count" stroke="#AA22FF" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}


export default DashB;