import React, { useState, useEffect } from 'react';
import { getToken, isSignedIn, removeToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';

const host = 'https://api.dataxo.info';

function AppHeader() {
  const token = getToken();
  const [signedIn, setSignedIn] = useState(isSignedIn());
  const [profileName, setProfileName] = useState('');
  const [roleId, setRoleId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setSignedIn(isSignedIn());
    const dispName = localStorage.getItem('dispName');
    const roleId = localStorage.getItem('roleId');
    setProfileName(dispName || ''); //if dispName is null or undefined set to empty
    setRoleId(roleId || ''); //if roleId is null or undefined set to empty
    //check token expire
    const checkTokenExpiration = async () => {
      if (token) { //check available before request
        try {
          //request token 
          const response = await fetch(`${host}/api-user/validate-token`, {
            method: 'GET',
            headers: {
              'x-access-token': token
            }
          });
          if (!response.ok) {
            //if token expired
            handleSignOut();
          }
        } catch (error) {
          console.error('Error checking token expiration:', error);
        }
      }
    }

    checkTokenExpiration();
  }, [token]);

  const [clientIP, setClientIP] = useState('');
  useEffect(() => {
    //service for get  client IP address
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setClientIP(data.ip))
      .catch(error => console.error('Error fetching IP:', error));
  }, []);

  const handleSignOut = () => {
    removeToken();
    setSignedIn(false);
    const userId = localStorage.getItem('userId');
    const data = {
      clientIP,
      userId
    };
    //set signout to log
    const url = `${host}/api-user/signoutlog`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    navigate('/');
  };

  return (
    <div className="AppHeader">
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 border-bottom">
        <ul className="nav col-12 col-md-auto mb-2 justify-content-center">
          <li><a href="/" className="h5 nav-link px-2"><i className="bi bi-house-door" style={{ fontSize: "1.2rem", color: "#FFFFFF" }}></i> หน้าหลัก</a></li>
          <li><a href="/doc/rec" className="h5 nav-link px-2"><i className="bi bi-card-list" style={{ fontSize: "1.2rem", color: "#FFFFFF" }}></i> ข้อมูลเอกสาร</a></li>
          <li><a href="/about" className="h5 nav-link px-2"><i className="bi bi-info-circle" style={{ fontSize: "1.2rem", color: "#77DD11" }}></i> About</a></li>
        </ul>
        <ul className="nav col-12 col-md-auto mb-2 justify-content-center">
          {signedIn ? (
            <li>
              <span className="border rounded-3 m-2 p-1"><i className="bi bi-person-circle" style={{ fontSize: "1.7rem", color: "#0275d8" }}></i>{profileName} </span>
              {roleId == '1' ? <a href="/userrec" className="btn btn-primary" role="button" aria-pressed="true">ทะเบียนรายการผู้ใช้</a> : ''}
              <a href="" className="btn btn-danger mx-2" onClick={(e) => {
                e.preventDefault();
                handleSignOut();
              }}>ออกจากระบบ
              </a>
            </li>
          ) : (
            <>
              <li><a href="/signin" className="btn btn-outline-success me-2">ล็อกอิน</a></li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default AppHeader;