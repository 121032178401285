import React, { useState, useEffect } from 'react';
import { getToken, removeToken } from '../utils/auth-client.js';
import { useNavigate } from 'react-router-dom';
import Signup from './signup.jsx';
import EditUser from './edituser.jsx';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'rc-pagination';
import './custom-assets/rc-pagination.css';
import './custom-assets/j-custom-style.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Page404 from './404.jsx'

const host = 'https://api.dataxo.info';

function UserRec() {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (!token) {
            navigate('/signin');
            return;
        }
    }, [])

    const [orderBy, setOrderBy] = useState('user_dispname');
    const [orderType, setOrderType] = useState('ASC');
    const [startRows, setStartRows] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(() => {
        //get the itemsPerPage from local storage if it exists
        const storedItemsPerPage = sessionStorage.getItem('itemsPerPage');
        return storedItemsPerPage ? parseInt(storedItemsPerPage) : 5; //default value if not found
    });
    const [currentPage, setCurrentPage] = useState(() => {
        //get the currentPage from local storage if it exists
        const storedCurrentPage = sessionStorage.getItem('currentPage');
        return storedCurrentPage ? parseInt(storedCurrentPage) : 1; //default value if not found
    });

    const [userDataList, setUserDataList] = useState([]);
    const [renderTrigger, setRenderTrigger] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [reLoading, setReLoading] = useState(true);
    const [error, setError] = useState(null);

    const [seachUser, setSearchDoc] = useState(() => {
        //get the search text from local storage if it exists
        const storedSearchDoc = sessionStorage.getItem('seachUser');
        return storedSearchDoc ? storedSearchDoc : ''; //default value if not found
    });
    const handleSeachUserChange = (inputtext) => {
        setSearchDoc(inputtext);
        //update local storage with the new value
        sessionStorage.setItem('seachUser', inputtext);
    }

    const handleItemsPerPageSelect = (pages) => {
        if (itemsPerPage !== pages) {
            setItemsPerPage(pages);
            // stay on current page
            setCurrentPage(currentPage);
            //update local storage with the new value
            sessionStorage.setItem('itemsPerPage', pages);
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        //update local storage with the new value
        sessionStorage.setItem('currentPage', page);
    }

    const ItemsPerpageSelector = () => {
        return (
            <DropdownButton
                variant="outline-secondary align-items-top d-inline-flex m-1 p-1"
                id="itemsperpage"
                title={`แสดง ${itemsPerPage} รายการ`}>
                <Dropdown.Item eventKey="5" onClick={() => handleItemsPerPageSelect(5)}>5</Dropdown.Item>
                <Dropdown.Item eventKey="10" onClick={() => handleItemsPerPageSelect(10)}>10</Dropdown.Item>
                <Dropdown.Item eventKey="20" onClick={() => handleItemsPerPageSelect(20)}>20</Dropdown.Item>
                <Dropdown.Item eventKey="30" onClick={() => handleItemsPerPageSelect(30)}>30</Dropdown.Item>
                <Dropdown.Item eventKey="40" onClick={() => handleItemsPerPageSelect(40)}>40</Dropdown.Item>
                <Dropdown.Item eventKey="50" onClick={() => handleItemsPerPageSelect(50)}>50</Dropdown.Item>
                <Dropdown.Item eventKey="100" onClick={() => handleItemsPerPageSelect(100)}>100</Dropdown.Item>
            </DropdownButton>
        );
    }

    const RcPagination = ({ totalItems, itemsPerPage, currentPage, onChange }) => {
        return (
            <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={totalItems}
                onChange={onChange}
                locale={'th-TH'}
                showTotal={(total, range) => `${range[0]} ถึง ${range[1]} จาก ${total} รายการ`}
                className="d-inline-flex justify-content-end m-2"
            />
        );
    }

    const altOrderType = () => {
        if (orderType === 'ASC') {
            setOrderType('DESC');
        } else {
            setOrderType('ASC');
        }
    }

    //list users with order for pagination
    //first loading page
    useEffect(() => {
        const storedCurrentPage = sessionStorage.getItem('currentPage');
        const storedItemsPerPage = sessionStorage.getItem('itemsPerPage');

        if (storedCurrentPage && storedItemsPerPage) {
            setCurrentPage(parseInt(storedCurrentPage));
            setItemsPerPage(parseInt(storedItemsPerPage));
        }
    }, []);

    //count all in documents while first loading page or search
    useEffect(() => {
        const fetchData = async () => {
            let searchparam = seachUser;
            // format search text for prevent error with slash or backslash
            searchparam = searchparam.toLowerCase().trim().replace(/\//g, 'sl0xx0xx').replace(/\\/g, 'bs0xx0xx');

            if (searchparam.length === 0) {
                searchparam = 'nosearch';
            }
            try {
                const response = await fetch(`${host}/api-user/user/page/${searchparam}`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (response.ok) {
                    const userDataResponse = await response.json();
                    setTotalItems(userDataResponse[0].count);
                } else {
                    handleUnauthResponse(response);
                    switch (response.status) {
                        case 403:
                            setError('คุณไม่ได้รับสิทธิ์เข้าถึงข้อมูลนี้');
                            break;
                        case 429:
                            setError(`มีการดึงข้อมูลเกินกำหนด โปรดรอแล้วลองใหม่อีกครั้ง`);
                            break;
                        default:
                            setError('เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง');
                    }
                    throw new Error('Network response was not ok');
                }
            } catch (err) {
                console.error('Error fetching data:', err);
                handleUnauthResponse(err.response);
            }
        }

        fetchData();
    }, [seachUser, renderTrigger])

    //main state changed
    useEffect(() => {
        const fetchData = async () => {
            let searchparam = seachUser;
            // format search text for prevent error with slash or backslash
            searchparam = searchparam.toLowerCase().trim().replace(/\//g, 'sl0xx0xx').replace(/\\/g, 'bs0xx0xx');
            if (searchparam.length === 0) {
                searchparam = 'nosearch';
            }
            setIsLoading(true);
            try {
                let skip = (currentPage - 1) * itemsPerPage;
                if (skip === totalItems || skip > totalItems) skip = 0;
                const limit = itemsPerPage;
                const response = await fetch(`${host}/api-user/user/page/${orderBy}/${orderType}/${skip}/${limit}/${searchparam}`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (response.ok) {
                    setUserDataList(await response.json())
                } else {
                    handleUnauthResponse(response);
                    switch (response.status) {
                        case 403:
                            setError('คุณไม่ได้รับสิทธิ์เข้าถึงข้อมูลนี้');
                            break;
                        case 429:
                            setError(`มีการดึงข้อมูลเกินกำหนด โปรดรอแล้วลองใหม่อีกครั้ง`);
                            break;
                        default:
                            setError('เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง');
                    }
                    throw new Error('Network response was not ok');
                }
            } catch (err) {
                console.error('Error fetching data:', err);
                handleUnauthResponse(err.response);
            } finally {
                setIsLoading(false);
                setReLoading(true);
            }
        }

        fetchData();
    }, [orderBy, orderType, currentPage, itemsPerPage, totalItems, seachUser, renderTrigger]);


    const [isModalSignupOpen, setIsModalSignupOpen] = useState(false);

    const openModalSignup = (text) => {
        setIsModalSignupOpen(true);
    }

    const closeModalSignup = () => {
        setIsModalSignupOpen(false);
        setRenderTrigger(prevTrigger => prevTrigger + 1)
    }

    const ModalSignup = () => {
        return (
            <Modal show={isModalSignupOpen} onExit={closeModalSignup} size="lg" centered>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex justify-content-start h4 p-2">
                        บันทึกข้อมูลผู้ใช้
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={closeModalSignup} className="btn bg-transparent"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                    </div>
                </div>
                <div className="modal-content align-items-center justify-content-center w-100">
                    <Signup />
                </div>
            </Modal>
        );
    }

    //edit user window ////////////////////////////
    const [isModalEditUserOpen, setIsModalEditUserOpen] = useState(false);
    const [editUserId, setEditUserId] = useState(null);

    const openModalEditUser = (id) => {
        setEditUserId(id);
        setIsModalEditUserOpen(true);
    }

    const closeModalEditUser = () => {
        setIsModalEditUserOpen(false);
        setRenderTrigger(prevTrigger => prevTrigger + 1)
    }

    const ModalEditUser = () => {
        return (
            <Modal show={isModalEditUserOpen} onExit={closeModalEditUser} size="lg" centered>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex justify-content-start h4 p-2">
                        แก้ไขข้อมูลผู้ใช้
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={closeModalEditUser} className="btn bg-transparent"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                    </div>
                </div>
                <div className="modal-content align-items-center justify-content-center w-100">
                    <EditUser param={editUserId} />
                </div>
            </Modal>
        );
    }

    const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModalInfo = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalInfoOpen(true);
    }

    const closeModalInfo = () => {
        setIsModalInfoOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        }
        if (modalType === 2) {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalInfoOpen} onExit={closeModalInfo} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModalInfo} className="btn bg-transparents">
                        <i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i>
                    </button>
                </div>
            </Modal>
        );
    }

    const handleUnauthResponse = (response) => {
        if (response && (response.status === 401 || response.status === 403)) {
            removeToken();
            navigate('/signin');
            return;
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            <div className="flex-grow-1 m-3">
                {!error ? (
                    <input id="searchuser"
                        type="text"
                        value={seachUser}
                        className="form-control"
                        placeholder="ค้นหาผู้ใช้..."
                        style={{ width: '250px', paddingRight: '30px', position: 'absolute', right: '20px' }}
                        onChange={(e) => handleSeachUserChange(e.target.value)}
                    />
                ) : ''}
            </div>
            {isLoading && reLoading ? (
                <div>กำลังโหลดข้อมูล...</div>
            ) : error ? (
                <div className="text-warning">{error}<Page404 /></div>
            ) : (
                <React.Fragment>
                    <div className="DocRec">
                        <ModalSignup />
                        <ModalEditUser />
                        <ModalInfo />
                        <div className="h6 d-inline-block">
                            <i className="bi bi-people-fill" style={{ fontSize: '1.7rem', color: '#777777' }} />
                        </div>
                        <div className="d-inline">
                            <button
                                type="button"
                                onClick={openModalSignup}
                                className="btn btn-outline-primary px-5 py-0 mx-5"
                            >
                                <i className="bi bi-person-plus-fill" style={{ fontSize: '1.7rem', color: '#DD2299' }} />
                            </button>
                        </div>
                        <div className="d-flex justify-content-end">
                            <RcPagination
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                onChange={handlePageChange}
                            />
                            <ItemsPerpageSelector />
                        </div>
                        <table className="table table-hover table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('user_dispname'); altOrderType(); }}>ชื่อ</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('username'); altOrderType(); }}>Username</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('user_email'); altOrderType(); }}>อีเมล</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('section_name'); altOrderType(); }}>หน่วยงาน</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('userrole_name'); altOrderType(); }}>บทบาท</button>
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {userDataList.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>{startRows + index + 1}</td>
                                            <td>{data.user_dispname}</td>
                                            <td>{data.username}</td>
                                            <td>{data.user_email}</td>
                                            <td>{data.section_name}</td>
                                            <td>{data.userrole_name}</td>
                                            <td>
                                                <button onClick={() => openModalEditUser(data.user_id)} className="btn bg-transparents"><i className="bi bi-pencil-square" style={{ fontSize: "1.1rem", color: "#22CC99" }}></i></button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end">
                            <RcPagination
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                onChange={handlePageChange}
                            />
                            <ItemsPerpageSelector />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default UserRec;