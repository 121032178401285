import { Outlet } from 'react-router-dom';
import AppHeader from './header';
import AppFooter from './footer';

function Layout() {
    return (
        <div className="Layout">
            <div className="header">
                <AppHeader />
            </div>
            <div className='Outlet'>
            <Outlet />
            </div>
            <div className='footer'>
                <AppFooter />
            </div>
        </div>
    )
}

export default Layout;