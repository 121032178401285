import React, { useState, useEffect, useRef } from 'react';
import { getToken, removeToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import th from "date-fns/locale/th";
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

registerLocale('th', th);
const host = 'https://api.dataxo.info';

function EditInDoc(id) {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (!token) {
            navigate('/signin');
            return;
        }
    }, [])

    const handleUnauthResponse = (response) => {
        if (response && (response.status === 401 || response.status === 403)) {
            removeToken();
            navigate('/signin');
            return;
        } else {
            window.location.reload();
        }
    }

    const userId = localStorage.getItem('userId');
    const [clientIP, setClientIP] = useState('');
    useEffect(() => {
        //service for get  client IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setClientIP(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [startDate1, setStartDate1] = useState(null, null);
    const [startDate2, setStartDate2] = useState(null, null);
    const [startDate3, setStartDate3] = useState(null, null);

    const [inputdoc_get_no, setInputdoc_get_no] = useState('');
    const [inputdoc_get_y, setInputdoc_get_y] = useState('');
    const [inputdoc_no, setInputdoc_no] = useState('');
    const [inputdoc_date, setInputdoc_date] = useState('');
    const [office_name, setOffice_name] = useState('');
    const [year_filter, setYear_filter] = useState('');
    const [ungroupdoc, setUnGroupdoc] = useState('');
    const [groupdoc, setGroupdoc] = useState('');

    const officeref = useRef(null);
    const groupyref = useRef(null);
    const groupref = useRef(null);

    //get data by document id parameter
    const [editId, setEditId] = useState(id.param);
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (editId != null) {
                const url = `${host}/api-data/indoc/${editId}`;
                try {
                    const response = await fetch(url,
                        {
                            method: 'GET',
                            headers: {
                                'x-access-token': token
                            }
                        });
                    if (response.ok) {
                        const data = await response.json();
                        data.map((data) => (
                            setInputdoc_get_no(data.inputdoc_get_no),
                            setInputdoc_get_y(data.inputdoc_get_y.toString()),
                            setInputdoc_no(data.inputdoc_no),
                            setInputdoc_date(format(data.inputdoc_date, 'yyyy-MM-dd')),
                            setOffice_name(data.office_name),
                            setGroupIdParam(data.groupdoc_id)
                        ))
                    } else {
                        console.error("Failed to fetch data:", response.statusText);
                        handleUnauthResponse(response);
                        throw new Error('Network response was not ok');
                    }
                } catch (err) {
                    console.error("Error fetching data:", err);
                    handleUnauthResponse(err.response);
                } finally {
                    setIsLoading(false);
                }
            }
        }
        fetchData();
    }, [editId]);

    const [isChGroupChecked, setIsChGroupChecked] = useState(false);
    const handleChGroupCheckChange = (event) => {
        setIsChGroupChecked(event.target.checked);
        if (isChGroupChecked) {
            //clear group input
            setGroupdoc('');
        }
    }

    const [isUnGroupChecked, setIsUnGroupChecked] = useState(false);
    const handleUnGroupCheckChange = (event) => {
        setIsUnGroupChecked(event.target.checked);
        if (isUnGroupChecked) {
            //flag for add to new group
            setUnGroupdoc('true');
            setGroupdoc('');
            setIsChGroupChecked(false);
        }
    }

    const [dataOffice, setDataOffice] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/office`;
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'x-access-token': token
                }
            })
            .then(res => res.json())
            .then(json => setDataOffice(json));
    }, []);

    let [groupIdParam, setGroupIdParam] = useState(null);
    let [groupDocList, setGroupDocList] = useState([]);
    useEffect(() => {
        const gid = groupIdParam;
        const groupdocurl = `${host}/api-data/indoc/group/${gid}`;
        fetch(groupdocurl,
            {
                method: 'GET',
                headers: {
                    'x-access-token': token
                }
            })
            .then(res => res.json())
            .then(json => setGroupDocList(json));
    }, [groupIdParam]);

    let [yearDocParam, setYearDocParam] = useState(null);
    const [dataInputdoc, setDataInputdoc] = useState([]);
    useEffect(() => {
        let y = yearDocParam;
        const gid = groupIdParam;
        if (y === '') { y = null }
        const inputdocurl = `${host}/api-data/indoc/${y}/no/${gid}`;
        fetch(inputdocurl,
            {
                method: 'GET',
                headers: {
                    'x-access-token': token
                }
            })
            .then(res => res.json())
            .then(json => setDataInputdoc(json));
    }, [yearDocParam, groupIdParam]);

    const handleSubmit = async (event) => {
        const gid = groupIdParam;
        event.preventDefault();
        const url = `${host}/api-input/updateindoc/${editId}/${gid}`;
        const formData = {
            inputdoc_get_no,
            inputdoc_get_y,
            inputdoc_no,
            inputdoc_date,
            office_name,
            ungroupdoc,
            groupdoc,
            clientIP,
            userId
        }
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then((json, modal) => {
                if (json === 'success') {
                    openModalInfo('บันทึกข้อมูลเอกสารเรียบร้อยแล้ว', modal = 1);
                }
                if (json === 'unchanged') {
                    openModalInfo('ไม่มีการบันทึก เนื่องจากไม่มีการเปลี่ยนแปลง', modal = 2);
                }
                if (json === 'exist') {
                    openModalInfo('ไม่มีการบันทึก เนื่องจากมีข้อมูลเอกสารนี้อยู่แล้ว', modal = 2);
                }
                if (json === 'syserror') {
                    openModalInfo('ไม่มีการบันทึก เนื่องจากเกิดปัญหาจากระบบ', modal = 2);
                }
            });
    }

    const [isDelIndoc, setIsDelIndoc] = useState(false);
    useEffect(() => {
        if (isDelIndoc) {
            const id = editId;
            const url = `${host}/api-input/indoc/del/${id}`;
            const formData = { clientIP, userId }
            fetch(url,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': token
                    },
                    body: JSON.stringify(formData)
                })
                .then(res => res.json())
                .then((json, modal) => {
                    setIsModalDelOpen(false);
                    if (json === 'deleted') {
                        openModalInfo('ลบเอกสารเรียบร้อยแล้ว', modal = 1);
                    }
                    if (json === 'delete-err') {
                        openModalInfo('ไม่สามารถลบได้', modal = 2);
                    }
                });
        }
    }, [isDelIndoc, editId]);

    const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
    const [isModalDelOpen, setIsModalDelOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModalInfo = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalInfoOpen(true);
    }

    const closeModalInfo = () => {
        setIsModalInfoOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        }
        if (modalType === 2) {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalInfoOpen} onExit={closeModalInfo} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModalInfo} className="btn bg-transparents"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                </div>
            </Modal>
        );
    }

    const openModalDel = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalDelOpen(true);
    }

    const closeModalDel = () => {
        setIsModalDelOpen(false);
    }

    const ModalDel = () => {
        return (
            <Modal show={isModalDelOpen} onExit={closeModalDel} size="sm" centered>
                <div className='modal-content align-items-center justify-content-center border-1 border-danger'>
                    <p className="fs-5 p-3">{modalText}</p>
                    <div className="d-inline">
                        <button onClick={() => setIsDelIndoc(true)} className="btn btn-outline-light py-2 ms-5 m-3"><i className="bi bi-trash3" style={{ fontSize: "1.2rem", color: "#FF4466" }}></i> ลบ</button>
                        <button onClick={closeModalDel} className="btn btn-success py-2 ms-5 m-3">ยกเลิก</button>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <React.Fragment>
            {isLoading ? <div>กำลังโหลดข้อมูล...</div>
                :
                <div className="align-items-center justify-content-center w-100 p-2">
                    <form onSubmit={handleSubmit} method="post" autoComplete="off">
                        <div className="h5 p-2 bg-success rounded-3">หนังสือรับเข้า</div>
                        <div className="input-group m-3">
                            <div className="form-floating">
                                <input id="inputdoc_get_no" name="inputdoc_get_no" defaultValue={inputdoc_get_no}
                                    onChange={(e) => setInputdoc_get_no(e.target.value)}
                                    onBlur={(e) => setInputdoc_get_no(e.target.value.trim())}
                                    type="text" className="form-control border-2" maxLength="45" required />
                                <label htmlFor="inputdoc_get_no" className="text-light">เลขรับ</label>
                            </div>
                            <div className="form-floating mx-3">
                                <DatePicker
                                    selected={startDate1}
                                    onChange={
                                        (date) => {
                                            if (date != null) {
                                                date = format(date, 'yyyy');
                                            }
                                            setStartDate1(date);
                                            setInputdoc_get_y(date);
                                        }
                                    }
                                    showYearPicker
                                    yearItemNumber={3}
                                    dateFormat="yyyy"
                                    locale={th - th}
                                    todayButton="ปีนี้"
                                    id="inputdoc_get_y" name="inputdoc_get_y" value={inputdoc_get_y} type="text" className="form-control pt-4 border-2" maxLength="4" required />
                                <label htmlFor="inputdoc_get_y" className="text-light p-1 ps-2 small">ปี</label>
                            </div>
                        </div>
                        <div className="input-group mx-3">
                            <div className="form-floating me-3">
                                <input id="inputdoc_no" name="inputdoc_no" defaultValue={inputdoc_no} onChange={(e) => setInputdoc_no(e.target.value)} onBlur={(e) => setInputdoc_no(e.target.value.trim())} type="text" className="form-control border-2" maxLength="45" required />
                                <label htmlFor="inputdoc_no" className="text-light">เลขหนังสือ</label>
                            </div>
                            <div className="form-floating me-3">
                                <DatePicker
                                    selected={startDate2}
                                    onChange={(date) => {
                                        if (date != null) {
                                            date = format(date, 'yyyy-MM-dd');
                                        }
                                        setStartDate2(date);
                                        setInputdoc_date(date);
                                    }}
                                    showDatePicker
                                    dateFormat="yyyy-MM-dd"
                                    locale={th}
                                    todayButton="วันนี้"
                                    id="inputdoc_date" name="inputdoc_date" value={inputdoc_date} type="text" className="form-control pt-4 border-2" required />
                                <label htmlFor="inputdoc_date" className="text-light p-1 ps-2 small">ลงวันที่</label>
                            </div>
                        </div>
                        <div className="mx-3">
                            <div className="form-floating">
                                <Typeahead
                                    ref={officeref}
                                    defaultInputValue={office_name.toString()}
                                    onChange={(selected) => setOffice_name(selected)}
                                    onBlur={(e) => setOffice_name(e.target.value.trim())}
                                    options={dataOffice.map((dataOffice, index) => (dataOffice.office_name))}
                                    highlightOnlyResult
                                    emptyLabel="ไม่เคยมีในฐานข้อมูล"
                                    paginationText="ต่อไป..."
                                    inputProps={{ required: true, className: "form-control mt-3 border-2" }}
                                    id="office_name" name="office_name" value={office_name} type="text" style={{ height: '80px' }}
                                    maxLength="100" />
                                <label htmlFor="office_name" className="text-light small">จากหน่วยงาน/บุคคล</label>
                            </div>
                        </div>
                        <div id="grouplist" className="mt-3 ms-3">
                            เอกสารในกลุ่ม
                            {groupDocList.length > 1 ?
                                <>
                                    {groupDocList.map((data, index) => (
                                        <div key={index} className={data.inputdoc_id === editId ? 'text-light border border-success rounded-1 py-1' : isUnGroupChecked ? 'text-decoration-line-through text-secondary' : ''}>
                                            {"   " + (index + 1) + '. เลขที่ ' + data.inputdoc_no + ' วันที่ ' + new Date(data.inputdoc_date).toLocaleDateString('th-TH') + ' เลขรับ ' + data.inputdoc_get_no}
                                            {data.inputdoc_id === editId ? '  *****[เอกสารนี้]' : ''}
                                        </div>
                                    ))}
                                </>
                                : ' : ไม่มี'
                            }
                        </div>
                        {groupDocList.length > 1 ?
                            <div className="ms-3 mt-3">
                                <input id="ungroupcheckbox" name="ungroupcheckbox" value={ungroupdoc} type="checkbox" className="form-check-input"
                                    checked={isUnGroupChecked}
                                    onChange={handleUnGroupCheckChange}
                                    onBlur={handleUnGroupCheckChange} />
                                <label className="form-check-label mx-2" htmlFor="ungroupcheckbox">
                                    นำเอกสารอื่นออกจากกลุ่ม
                                </label>
                            </div>
                            : ''
                        }
                        {isUnGroupChecked === false && (
                            <div>
                                <div className="ms-3 mt-3">
                                    <input id="chgroupcheckbox" name="chgroupcheckbox" type="checkbox" className="form-check-input"
                                        checked={isChGroupChecked}
                                        onChange={handleChGroupCheckChange} />
                                    <label className="form-check-label mx-2" htmlFor="chgroupcheckbox">
                                        เปลี่ยนกลุ่มเอกสาร
                                    </label>
                                </div>
                                <div className="m-3">
                                    {isChGroupChecked && (
                                        <div id="chgroup" className="ms-5">
                                            <div className="form-floating" style={{ zIndex: '3' }}>
                                                <DatePicker
                                                    ref={groupyref}
                                                    selected={startDate3}
                                                    onChange={(date) => {
                                                        if (date != null) {
                                                            date = format(date, 'yyyy');
                                                        }
                                                        setStartDate3(date);
                                                        setYear_filter(date);
                                                        groupref.current.clear();
                                                    }}
                                                    showYearPicker
                                                    yearItemNumber={3}
                                                    dateFormat="yyyy"
                                                    locale={th}
                                                    todayButton="ปีนี้"
                                                    id="year_filter" value={year_filter} type="text" className="form-control pt-4 border-2" maxLength="4" />
                                                <label htmlFor="year_filter" className="text-light p-1 ps-2 small">ปี</label>
                                            </div>
                                            <div className="form-floating">
                                                <Typeahead
                                                    ref={groupref}
                                                    onFocus={() => setYearDocParam(groupyref.current.props.value)}
                                                    onChange={(selected) => setGroupdoc(selected)}
                                                    onBlur={(e) => setGroupdoc(e.target.value.trim())}
                                                    options={dataInputdoc.map((dataInputdoc, index) => ('เลขที่ ' + dataInputdoc.inputdoc_no + ' วันที่ ' + new Date(dataInputdoc.inputdoc_date).toLocaleDateString('th-TH') + ' เลขรับ ' + dataInputdoc.inputdoc_get_no))}
                                                    highlightOnlyResult
                                                    emptyLabel="ไม่เคยมีในปีที่เลือก"
                                                    paginationText="ต่อไป..."
                                                    id="groupdoc" name="groupdoc" value={groupdoc} type="text" style={{ height: '80px' }}
                                                    maxLength="45" inputProps={{ className: "form-control mt-3 border-2", required: isChGroupChecked }} />
                                                <label htmlFor="group_no" className="text-light small">เลขรับ/เลขหนังสือ</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <button className="btn btn-success py-2 ms-5 mt-3 ms-3" type="submit">บันทึกแก้ไข</button>
                    </form >
                    <div className="d-flex flex-column align-items-end">
                        <OverlayTrigger
                            placement="left"
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    ลบเอกสารนี้
                                </Tooltip>
                            )}>
                            <button type="button" onClick={() => openModalDel('ลบเอกสารออกจากฐานข้อมูลหรือไม่?')} className="btn bg-transparents align-items-end"><i className="bi bi-trash3" style={{ fontSize: "1.2rem", color: "#FFFFFF" }}></i></button>
                        </OverlayTrigger>
                    </div>
                    <>
                        <ModalInfo />
                    </>
                    <>
                        <ModalDel />
                    </>
                </div >
            }
        </React.Fragment>
    )
}

export default EditInDoc;