import React, { useState, useEffect, useRef } from 'react';
import { getToken, removeToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import th from "date-fns/locale/th";
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import Modal from 'react-bootstrap/Modal';

const host = 'https://api.dataxo.info';

function OutputDoc(id) {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (!token) {
            navigate('/signin');
            return;
        }
    }, [])

    const handleUnauthResponse = (response) => {
        if (response && (response.status === 401 || response.status === 403)) {
            removeToken();
            navigate('/signin');
            return;
        } else {
            window.location.reload();
        }
    }

    const userId = localStorage.getItem('userId');
    const [clientIP, setClientIP] = useState('');
    useEffect(() => {
        //service for get  client IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setClientIP(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);


    // get data by document id parameter
    const [indocId, setIndocId] = useState([id.param]);
    const [indocData, setIndocData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (indocId != null) {
                const url = `${host}/api-data/indoc/${indocId}`;
                try {
                    const response = await fetch(url,
                        {
                            method: 'GET',
                            headers: {
                                'x-access-token': token
                            }
                        });
                    if (response.ok) {
                        const data = await response.json();
                        setIndocData(data);
                    } else {
                        console.error("Failed to fetch data:", response.statusText);
                        handleUnauthResponse(response);
                        throw new Error('Network response was not ok');
                    }
                } catch (err) {
                    console.error("Error fetching data:", err);
                    handleUnauthResponse(err.response);
                }
            }
        }
        fetchData();
    }, [indocId]);

    const [startDate, setStartDate] = useState(null, null);
    const [outputdoc_no, setOutputdoc_no] = useState('');
    const [outputdoc_date, setOutputdoc_date] = useState('');
    const [office_name, setOffice_name] = useState('');
    const [officer_name, setOfficer_name] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');

    const officeref = useRef(null);
    const officerref = useRef(null);
    const emailref = useRef(null);
    const addressref = useRef(null);

    const [dataOffice, setDataOffice] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/office`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataOffice(json));
    }, []);

    const [dataOfficer, setDataOfficer] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/officer`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataOfficer(json));
    }, []);

    const [dataEmail, setDataEmail] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/email`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataEmail(json));
    }, []);

    const [dataAddress, setDataAddress] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/address`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataAddress(json));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${host}/api-input/insoutdoc/${indocId}`;
        const formData = {
            outputdoc_no,
            outputdoc_date,
            office_name,
            officer_name,
            email,
            address,
            clientIP,
            userId
        }
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token
            },
            body: JSON.stringify(formData)
        })
            .then(res => res.json())
            .then((json, modal) => {
                if (json === 'success') {
                    openModal('บันทึกข้อมูลเอกสารเรียบร้อยแล้ว', modal = 1);
                }
                if (json === 'exist') {
                    openModal('ไม่มีการบันทึก เนื่องจากมีข้อมูลเอกสารนี้อยู่แล้ว', modal = 2);
                }
                if (json === 'syserror') {
                    openModal('ไม่มีการบันทึก เนื่องจากเกิดปัญหาจากระบบ', modal = 2);
                }
            });
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModal = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        } else {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalOpen} onExit={closeModal} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModal} className="btn bg-transparents"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                </div>
            </Modal>
        );
    }

    return (
        <div className="align-items-center justify-content-center w-100 p-2">
            <form onSubmit={handleSubmit} method="post" autoComplete="off">
                <div className="h5 text-dark p-2 bg-warning rounded-3">หนังสือส่งออก</div>
                <div className="text-light m-3">
                    {indocData.map((data, index) => (
                        `หนังสือรับเข้า ${data.inputdoc_no} ลงวันที่ ${new Date(data.inputdoc_date).toLocaleDateString('th-TH')}    เลขรับ ${data.inputdoc_get_no}`
                    ))}
                </div>
                <div className="input-group mx-3">
                    <div className="form-floating me-3">
                        <input id="outputdoc_no" name="outputdoc_no" value={outputdoc_no}
                            onChange={(e) => setOutputdoc_no(e.target.value)}
                            onBlur={(e) => setOutputdoc_no(e.target.value.trim())}
                            type="text" className="form-control border-2" maxLength="45" required />
                        <label htmlFor="outputdoc_no" className="text-light">เลขหนังสือ</label>
                    </div>
                    <div className="form-floating me-3">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                if (date != null) {
                                    date = format(date, 'yyyy-MM-dd');
                                }
                                setStartDate(date);
                                setOutputdoc_date(date);
                            }}
                            showDatePicker
                            dateFormat="yyyy-MM-dd"
                            locale={th}
                            todayButton="วันนี้"
                            id="outputdoc_date" name="outputdoc_date" value={outputdoc_date} type="text" className="form-control pt-4 border-2" required />
                        <label htmlFor="outputdoc_date" className="text-light p-1 ps-2 small">ลงวันที่</label>
                    </div>
                </div>
                <div className="mx-3">
                    <div className="form-floating">
                        <Typeahead
                            ref={officeref}
                            onChange={(selected) => setOffice_name(selected)}
                            onBlur={(e) => setOffice_name(e.target.value.trim())}
                            options={dataOffice.map((dataOffice, index) => (dataOffice.office_name))}
                            highlightOnlyResult
                            emptyLabel="ไม่เคยมีในฐานข้อมูล"
                            paginationText="ต่อไป..."
                            inputProps={{ required: true, className: "form-control mt-3 border-2" }}
                            id="office_name" name="office_name" value={office_name} type="text" style={{ height: '80px' }}
                            maxLength="100" />
                        <label htmlFor="office_name" className="text-light small">ส่งหน่วยงาน/บุคคล</label>
                    </div>
                </div>
                <div className="mx-3">
                    <div className="form-floating">
                        <Typeahead
                            ref={officerref}
                            onChange={(selected) => setOfficer_name(selected)}
                            onBlur={(e) => setOfficer_name(e.target.value.trim())}
                            options={dataOfficer.map((dataOfficer, index) => (dataOfficer.officer_name))}
                            highlightOnlyResult
                            emptyLabel="ไม่เคยมีในฐานข้อมูล"
                            paginationText="ต่อไป..."
                            inputProps={{ required: false, className: "form-control mt-3 border-2 w-50" }}
                            id="officer_name" name="officer_name" value={officer_name} type="text" style={{ height: '80px' }}
                            maxLength="45" />
                        <label htmlFor="officer_name" className="text-light small">ชื่อเจ้าหน้าที่</label>
                    </div>
                </div>
                <div className="mx-3">
                    <div className="form-floating">
                        <Typeahead
                            ref={emailref}
                            onChange={(selected) => setEmail(selected)}
                            onBlur={(e) => setEmail(e.target.value.trim())}
                            options={dataEmail.map((dataEmail, index) => (dataEmail.email))}
                            highlightOnlyResult
                            emptyLabel="ไม่เคยมีในฐานข้อมูล"
                            paginationText="ต่อไป..."
                            inputProps={{ required: false, className: "form-control mt-3 border-2 w-75" }}
                            id="email" name="email" value={email} type="text" style={{ height: '80px' }}
                            maxLength="200" />
                        <label htmlFor="email" className="text-light small">อีเมล</label>
                    </div>
                </div>
                <div className="mx-3">
                    <div className="form-floating">
                        <Typeahead
                            ref={addressref}
                            onChange={(selected) => setAddress(selected)}
                            onBlur={(e) => setAddress(e.target.value.trim())}
                            options={dataAddress.map((dataAddress, index) => (dataAddress.address))}
                            highlightOnlyResult
                            emptyLabel="ไม่เคยมีในฐานข้อมูล"
                            paginationText="ต่อไป..."
                            inputProps={{ required: false, className: 'form-control mt-3 border-2' }}
                            id="address" name="address" value={address} type="text" style={{ height: '80px' }}
                            maxLength="200" />
                        <label htmlFor="address" className="text-light small">ที่อยู่</label>
                    </div>
                </div>
                <button className="btn btn-primary py-2 ms-5 m-3" type="submit">บันทึก</button>
            </form >
            <>
                <ModalInfo />
            </>
        </div >
    )
}

export default OutputDoc;