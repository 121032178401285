import React, { useState, useEffect } from 'react';
import { getToken, removeToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';
import InDoc from './indoc';
import ImDoc from './imdoc';
import EditInDoc from './editindoc';
import OutDoc from './outdoc';
import EditOutDoc from './editoutdoc';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from 'rc-pagination';
import './custom-assets/rc-pagination.css';
import './custom-assets/j-custom-style.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Page404 from './404.jsx'

const host = 'https://api.dataxo.info';

function DocRec() {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (!token) {
            navigate('/signin');
            return;
        }
    }, [])

    const roleId = localStorage.getItem('roleId');
    const sectionId = localStorage.getItem('sectionId');
    const [idOfIndoc, setIdOfIndoc] = useState(null);
    const [orderBy, setOrderBy] = useState('inputdoc.inputdoc_date');
    const [orderType, setOrderType] = useState('DESC');
    const [startRows, setStartRows] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(() => {
        //get the itemsPerPage from local storage if it exists
        const storedItemsPerPage = sessionStorage.getItem('itemsPerPage');
        return storedItemsPerPage ? parseInt(storedItemsPerPage) : 5; //default value if not found
    });
    const [currentPage, setCurrentPage] = useState(() => {
        //get the currentPage from local storage if it exists
        const storedCurrentPage = sessionStorage.getItem('currentPage');
        return storedCurrentPage ? parseInt(storedCurrentPage) : 1; //default value if not found
    });
    const [lastActionIndocId, setLastActionIndocId] = useState(() => {
        //get the lastActionIndocId from local storage if it exists
        const storedlastActionIndocId = sessionStorage.getItem('lastActionIndocId');
        return storedlastActionIndocId ? parseInt(storedlastActionIndocId) : null; //default value if not found
    });

    const [indocDataWithCounts, setIndocDataWithCounts] = useState([]);
    const [renderTrigger, setRenderTrigger] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [reLoading, setReLoading] = useState(true);
    const [error, setError] = useState(null);

    const [searchDoc, setSearchDoc] = useState(() => {
        //get the search text from local storage if it exists
        const storedSearchDoc = sessionStorage.getItem('searchDoc');
        return storedSearchDoc ? storedSearchDoc : ''; //default value if not found
    });
    const handleSeachDocChange = (inputtext) => {
        setSearchDoc(inputtext);
        //update local storage with the new value
        sessionStorage.setItem('searchDoc', inputtext);
    }

    const handleItemsPerPageSelect = (pages) => {
        if (itemsPerPage !== pages) {
            setItemsPerPage(pages);
            // stay on current page
            setCurrentPage(currentPage);
            //update local storage with the new value
            sessionStorage.setItem('itemsPerPage', pages);
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        //update local storage with the new value
        sessionStorage.setItem('currentPage', page);
    }

    const ItemsPerpageSelector = () => {
        return (
            <DropdownButton
                variant="outline-secondary align-items-top d-inline-flex m-1 p-1"
                id="itemsperpage"
                title={`แสดง ${itemsPerPage} รายการ`}>
                <Dropdown.Item eventKey="5" onClick={() => handleItemsPerPageSelect(5)}>5</Dropdown.Item>
                <Dropdown.Item eventKey="10" onClick={() => handleItemsPerPageSelect(10)}>10</Dropdown.Item>
                <Dropdown.Item eventKey="20" onClick={() => handleItemsPerPageSelect(20)}>20</Dropdown.Item>
                <Dropdown.Item eventKey="30" onClick={() => handleItemsPerPageSelect(30)}>30</Dropdown.Item>
                <Dropdown.Item eventKey="40" onClick={() => handleItemsPerPageSelect(40)}>40</Dropdown.Item>
                <Dropdown.Item eventKey="50" onClick={() => handleItemsPerPageSelect(50)}>50</Dropdown.Item>
                <Dropdown.Item eventKey="100" onClick={() => handleItemsPerPageSelect(100)}>100</Dropdown.Item>
            </DropdownButton>
        );
    }

    const RcPagination = ({ totalItems, itemsPerPage, currentPage, onChange }) => {
        return (
            <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={totalItems}
                onChange={onChange}
                locale={'th-TH'}
                showTotal={(total, range) => `${range[0]} ถึง ${range[1]} จาก ${total} รายการ`}
                className="d-inline-flex justify-content-end m-2"
            />
        );
    }

    const altOrderType = () => {
        if (orderType === 'ASC') {
            setOrderType('DESC');
        } else {
            setOrderType('ASC');
        }
    }

    //list input documents with order for pagination
    //first loading page
    useEffect(() => {
        const storedCurrentPage = sessionStorage.getItem('currentPage');
        const storedItemsPerPage = sessionStorage.getItem('itemsPerPage');

        if (storedCurrentPage && storedItemsPerPage) {
            setCurrentPage(parseInt(storedCurrentPage));
            setItemsPerPage(parseInt(storedItemsPerPage));
        }
    }, []);

    //count all in documents while first loading page or search
    useEffect(() => {
        const fetchData = async () => {
            let searchparam = searchDoc;
            //format search text for prevent error with slash or backslash
            searchparam = searchparam.toLowerCase().trim().replace(/\//g, 'sl0xx0xx').replace(/\\/g, 'bs0xx0xx');

            if (searchparam.length === 0) {
                searchparam = 'nosearch';
            }
            try {
                const response = await fetch(`${host}/api-data/indoc/page/${searchparam}/${roleId}/${sectionId}`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (response.ok) {
                    const indocDataResponse = await response.json();
                    setTotalItems(indocDataResponse[0].count);
                } else {
                    handleUnauthResponse(response);
                    switch (response.status) {
                        case 403:
                            setError('คุณไม่ได้รับสิทธิ์เข้าถึงข้อมูลนี้');
                            break;
                        case 429:
                            setError(`มีการดึงข้อมูลเกินกำหนด โปรดรอแล้วลองใหม่อีกครั้ง`);
                            break;
                        default:
                            setError('เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง');
                    }
                    throw new Error('Network response was not ok');
                }
            } catch (err) {
                console.error('Error fetching data:', err);
                handleUnauthResponse(err.response);
            }
        }

        fetchData();
    }, [searchDoc, renderTrigger])

    //main state changed
    useEffect(() => {
        const fetchData = async () => {
            let searchparam = searchDoc;
            // format search text for prevent error with slash or backslash
            searchparam = searchparam.toLowerCase().trim().replace(/\//g, 'sl0xx0xx').replace(/\\/g, 'bs0xx0xx');
            if (searchparam.length === 0) {
                searchparam = 'nosearch';
            }
            setIsLoading(true);
            try {
                let skip = (currentPage - 1) * itemsPerPage;
                if (skip === totalItems || skip > totalItems) skip = 0;
                const limit = itemsPerPage;
                const response = await fetch(`${host}/api-data/indoc/page/${orderBy}/${orderType}/${skip}/${limit}/${searchparam}/${roleId}/${sectionId}`,
                    {
                        method: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    });
                if (response.ok) {
                    //get output documents
                    const indocDataResponse = await response.json();
                    const promises = indocDataResponse.map(async (indocData) => {
                        const [outdocResponse, sentResponse, trackResponse] = await Promise.all([
                            fetch(`${host}/api-data/outdoc/${indocData.inputdoc_id}`, {
                                method: 'GET',
                                headers: { 'x-access-token': token }
                            }),
                            fetch(`${host}/api-data/countsent/${indocData.inputdoc_id}`, {
                                method: 'GET',
                                headers: { 'x-access-token': token }
                            }),
                            fetch(`${host}/api-data/counttrack/${indocData.inputdoc_id}`, {
                                method: 'GET',
                                headers: { 'x-access-token': token }
                            })
                        ]);
                        const [outdocList, sentCount, trackCount] = await Promise.all([
                            outdocResponse.json(),
                            sentResponse.json(),
                            trackResponse.json()
                        ]);

                        return {
                            ...indocData,
                            outdocList,
                            sentCount: sentCount[0].count,
                            trackCount: trackCount[0].count
                        };
                    });
                    const data = await Promise.all(promises);
                    setIndocDataWithCounts(data);
                    setStartRows(skip + 1);
                } else {
                    handleUnauthResponse(response);
                    switch (response.status) {
                        case 403:
                            setError('คุณไม่ได้รับสิทธิ์เข้าถึงข้อมูลนี้');
                            break;
                        case 429:
                            setError(`มีการดึงข้อมูลเกินกำหนด โปรดรอแล้วลองใหม่อีกครั้ง`);
                            break;
                        default:
                            setError('เกิดข้อผิดพลาดในการดึงข้อมูล กรุณาลองใหม่อีกครั้ง');
                    }
                    throw new Error('Network response was not ok');
                }
            } catch (err) {
                console.error('Error fetching data:', err);
                handleUnauthResponse(err.response);
            } finally {
                setIsLoading(false);
                setReLoading(true);
            }
        }

        fetchData();
    }, [orderBy, orderType, currentPage, itemsPerPage, totalItems, searchDoc, idOfIndoc, lastActionIndocId, renderTrigger]);

    // show or hide output documents 
    const handleShowOutdoc = (id) => {
        //prevent reloading on click
        setReLoading(false);
        if (id === idOfIndoc & id === lastActionIndocId) {
            setIdOfIndoc(null);
            setLastActionIndocId(null);
            //update local storage with the new value
            sessionStorage.setItem('lastActionIndocId', null);
        }
        else {
            setIdOfIndoc(id);
            setLastActionIndocId(id);
            //update local storage with the new value
            sessionStorage.setItem('lastActionIndocId', id);
        }
    }

    useEffect(() => {
        if (idOfIndoc == null) {
            setIdOfIndoc(lastActionIndocId);
        }
        if (document.getElementById(`out_${idOfIndoc}`) != null) {
            if (document.getElementById(`out_${idOfIndoc}`).hidden === true) {
                document.getElementById(`out_${idOfIndoc}`).hidden = false;
            }
            //if is a new selection
            if (idOfIndoc !== lastActionIndocId) {
                if (document.getElementById(`out_${lastActionIndocId}`) != null) {
                    document.getElementById(`out_${lastActionIndocId}`).hidden = true;
                }
            }
        }
    }, [idOfIndoc, lastActionIndocId])

    // hide output documents action 
    useEffect(() => {
        if (document.getElementById(`out_${lastActionIndocId}`) != null) {
            document.getElementById(`out_${lastActionIndocId}`).hidden = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, itemsPerPage, orderBy, orderType])

    const [isModalIndocOpen, setIsModalIndocOpen] = useState(false);

    const openModalIndoc = (text) => {
        setIsModalIndocOpen(true);
    }

    const closeModalIndoc = () => {
        setIsModalIndocOpen(false);
        setRenderTrigger(prevTrigger => prevTrigger + 1)
    }

    const ModalIndoc = () => {
        return (
            <Modal show={isModalIndocOpen} onExit={closeModalIndoc} size="lg" centered>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex justify-content-start h4 p-2">
                        บันทึกข้อมูลเอกสาร
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={closeModalIndoc} className="btn bg-transparent"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                    </div>
                </div>
                <div className="modal-content align-items-center justify-content-center w-100">
                    <InDoc />
                </div>
            </Modal>
        );
    }

    //import documents data from file ///////////////////
    const [isModalImdocOpen, setIsModalImdocOpen] = useState(false);

    const openModalImdoc = () => {
        setIsModalImdocOpen(true);
    }

    const closeModalImdoc = () => {
        setIsModalImdocOpen(false);
        setRenderTrigger(prevTrigger => prevTrigger + 1)
    }

    const ModalImdoc = () => {
        return (
            <Modal show={isModalImdocOpen} onExit={closeModalImdoc} size="lg" centered>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex justify-content-start h4 p-2">
                        นำเข้าข้อมูลเอกสารจากไฟล์
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={closeModalImdoc} className="btn bg-transparent"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                    </div>
                </div>
                <div className="modal-content align-items-center justify-content-center w-100">
                    <ImDoc />
                </div>
            </Modal>
        );
    }

    //edit input document window ////////////////////////////
    const [isModalEditIndocOpen, setIsModalEditIndocOpen] = useState(false);
    const [editIndocId, setEditIndocId] = useState(null);

    const openModalEditIndoc = (id) => {
        setEditIndocId(id);
        //update local storage with the new value
        sessionStorage.setItem('lastActionIndocId', id);
        setIsModalEditIndocOpen(true);
    }

    const closeModalEditIndoc = () => {
        setIsModalEditIndocOpen(false);
        setRenderTrigger(prevTrigger => prevTrigger + 1)
    }

    const ModalEditIndoc = () => {
        return (
            <Modal show={isModalEditIndocOpen} onExit={closeModalEditIndoc} size="lg" centered>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex justify-content-start h4 p-2">
                        แก้ไขข้อมูลเอกสาร
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={closeModalEditIndoc} className="btn bg-transparent"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                    </div>
                </div>
                <div className="modal-content align-items-center justify-content-center w-100">
                    <EditInDoc param={editIndocId} />
                </div>
            </Modal>
        );
    }

    // insert output document window ////////////////////////////
    const [isModalOutdocOpen, setIsModalOutdocOpen] = useState(false);

    const openModalOutdoc = () => {
        setIsModalOutdocOpen(true);
    }

    const closeModalOutdoc = () => {
        setIsModalOutdocOpen(false);
        setRenderTrigger(prevTrigger => prevTrigger + 1)
    }

    const ModalOutdoc = () => {
        return (
            <Modal show={isModalOutdocOpen} onExit={closeModalOutdoc} size="lg" centered>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex justify-content-start h4 p-2">
                        บันทึกข้อมูลเอกสาร
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={closeModalOutdoc} className="btn bg-transparent"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                    </div>
                </div>
                <div className="modal-content align-items-center justify-content-center w-100">
                    <OutDoc param={idOfIndoc} />
                </div>
            </Modal>
        );
    }

    // edit output document window ////////////////////////////
    const [isModalEditOutdocOpen, setIsModalEditOutdocOpen] = useState(false);
    const [editOutdocId, setEditOutdocId] = useState(null);

    const openModalEditOutdoc = (id) => {
        setEditOutdocId(id);
        setIsModalEditOutdocOpen(true);
    }

    const closeModalEditOutdoc = () => {
        setIsModalEditOutdocOpen(false);
        setRenderTrigger(prevTrigger => prevTrigger + 1)
    }

    const ModalEditOutdoc = () => {
        return (
            <Modal show={isModalEditOutdocOpen} onExit={closeModalEditOutdoc} size="lg" centered>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex justify-content-start h4 p-2">
                        แก้ไขข้อมูลเอกสาร
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={closeModalEditOutdoc} className="btn bg-transparent"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                    </div>
                </div>
                <div className="modal-content align-items-center justify-content-center w-100">
                    <EditOutDoc param={editOutdocId} />
                </div>
            </Modal>
        );
    }

    const [sendOutdocId, setSendOutdocId] = useState(null);
    const [sendOfficeId, setSendOfficeId] = useState(null);
    const [sendEmailId, setSendEmailId] = useState(null);
    const [isSendOutdoc, setIsSendOutdoc] = useState(false);
    const [isModalSendOpen, setIsModalSendOpen] = useState(false);
    useEffect(() => {
        if (isSendOutdoc) {
            const id1 = sendOutdocId;
            const id2 = sendOfficeId;
            const id3 = sendEmailId;
            const url = `${host}/api-input/outdoc/send/${id1}/${id2}/${id3}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'x-access-token': token
                }
            })
                .then(res => res.json())
                .then((json, modal) => {
                    setIsModalSendOpen(false);
                    if (json === 'sent') {
                        sendLineMessage('-notify', 'สวัสดี, รบกวนช่วยส่งอีเมลให้หน่อยนะ')
                        openModalInfo('ส่งเอกสารเรียบร้อยแล้ว', modal = 1);
                        setRenderTrigger(prevTrigger => prevTrigger + 1);
                    }
                    if (json === 'send-err') {
                        openModalInfo('ไม่สามารถส่งได้', modal = 2);
                    }
                });
        }
    }, [isSendOutdoc, sendOutdocId, sendOfficeId, sendEmailId]);

    const openModalSend = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalSendOpen(true);
    }

    const closeModalSend = () => {
        setIsModalSendOpen(false);
    }

    const ModalSend = () => {
        return (
            <Modal show={isModalSendOpen} onExit={closeModalSend} size="sm" centered>
                <div className="modal-content align-items-center justify-content-center border-1" style={{ borderColor: '#00AAFF' }}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <div className="d-inline">
                        <button onClick={() => setIsSendOutdoc(true)} className="btn btn-outline-light py-2 ms-5 m-3">
                            <i className="bi bi-send" style={{ fontSize: "1.2rem", color: "#0088FF" }}></i> ส่งเลย
                        </button>
                        <button onClick={closeModalSend} className="btn btn-success py-2 ms-5 m-3">ยกเลิก</button>
                    </div>
                </div>
            </Modal>
        );
    }

    const [unSendOutdocId, setUnSendOutdocId] = useState(null);
    const [unSendOfficeId, setUnSendOfficeId] = useState(null);
    const [unSendEmailId, setUnSendEmailId] = useState(null);
    const [isUnSendOutdoc, setIsUnSendOutdoc] = useState(false);
    const [isModalUnSendOpen, setIsModalUnSendOpen] = useState(false);
    useEffect(() => {
        if (isUnSendOutdoc) {
            const id1 = unSendOutdocId;
            const id2 = unSendOfficeId;
            const id3 = unSendEmailId;
            const url = `${host}/api-input/outdoc/unsend/${id1}/${id2}/${id3}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'x-access-token': token
                }
            })
                .then(res => res.json())
                .then((json, modal) => {
                    setIsModalUnSendOpen(false);
                    if (json === 'unsend') {
                        openModalInfo('ยกเลิกการส่งเอกสารเรียบร้อยแล้ว', modal = 1);
                        setRenderTrigger(prevTrigger => prevTrigger + 1);
                    }
                    if (json === 'unsend-err') {
                        openModalInfo('ไม่สามารถยกเลิกได้', modal = 2);
                    }
                });
        }
    }, [isUnSendOutdoc, unSendOutdocId, unSendOfficeId, unSendEmailId]);

    const openModalUnSend = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalUnSendOpen(true);
    }

    const closeModalUnSend = () => {
        setIsModalUnSendOpen(false);
    }

    const ModalUnSend = () => {
        return (
            <Modal show={isModalUnSendOpen} onExit={closeModalUnSend} size="sm" centered>
                <div className='modal-content align-items-center justify-content-center border-1 border-danger'>
                    <p className="fs-5 p-3">{modalText}</p>
                    <div className="d-inline">
                        <button onClick={() => setIsUnSendOutdoc(true)} className="btn btn-outline-light py-2 ms-5 m-3">
                            <i className="bi bi-envelope-slash" style={{ fontSize: "2.0rem", color: "#FF3300" }}></i> ยกเลิกการส่งนี้
                        </button>
                        <button onClick={closeModalUnSend} className="btn btn-success py-2 ms-5 m-3">ปิด</button>
                    </div>
                </div>
            </Modal>
        );
    }

    const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModalInfo = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalInfoOpen(true);
    }

    const closeModalInfo = () => {
        setIsModalInfoOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        }
        if (modalType === 2) {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalInfoOpen} onExit={closeModalInfo} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModalInfo} className="btn bg-transparents">
                        <i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i>
                    </button>
                </div>
            </Modal>
        );
    }

    const sendLineMessage = async (messageType, message) => {
        const endpoint = messageType === 'notify' ?
            `/api-line/line/ntf/${message}` :
            `/api-line/line/pm/${message}`;
        const url = `${host}${endpoint}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'x-access-token': token
                }
            })
            if (response.ok) {
                console.log('LINE message sent.')
            } else {
                handleUnauthResponse(response);
                throw new Error('Network response was not ok');
            }
        } catch (err) {
            console.error('Error sending LINE message:', err);
            handleUnauthResponse(err.response);
        }
    }

    const handleUnauthResponse = (response) => {
        if (response && (response.status === 401 || response.status === 403)) {
            removeToken();
            navigate('/signin');
            return;
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            <div className="flex-grow-1 m-3">
                {!error ? (
                    <input id="searchdoc"
                        type="text"
                        value={searchDoc}
                        className="form-control"
                        placeholder="ค้นหาเอกสาร..."
                        style={{ width: '250px', paddingRight: '30px', position: 'absolute', right: '20px' }}
                        onChange={(e) => handleSeachDocChange(e.target.value)}
                    />
                ) : ''}
            </div>
            {isLoading && reLoading ? (
                <div>กำลังโหลดข้อมูล...</div>
            ) : error ? (
                <div className="text-warning">{error}<Page404 /></div>
            ) : (
                <React.Fragment>
                    <div className="DocRec">
                        <ModalIndoc />
                        <ModalImdoc />
                        <ModalEditIndoc />
                        <ModalOutdoc />
                        <ModalEditOutdoc />
                        <ModalSend />
                        <ModalUnSend />
                        <ModalInfo />
                        <div className="h6 d-inline-block">
                            <i className="bi bi-card-list" style={{ fontSize: '1.7rem', color: '#777777' }} />
                        </div>
                        <div className="d-inline">
                            <button
                                type="button"
                                onClick={openModalIndoc}
                                className="btn btn-outline-primary px-5 py-0 mx-5"
                            >
                                <i className="bi bi-plus-square-fill" style={{ fontSize: '1.7rem', color: '#DD2299' }} />
                            </button>
                            <button
                                type="button"
                                onClick={openModalImdoc}
                                className="btn btn-outline-primary px-5 py-0 mx-5"
                            >
                                <i className="bi bi-file-earmark-plus" style={{ fontSize: '1.7rem', color: '#DD2299' }} />
                                XLSX
                            </button>
                        </div>
                        <div className="d-flex justify-content-end">
                            <RcPagination
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                onChange={handlePageChange}
                            />
                            <ItemsPerpageSelector />
                        </div>
                        <table className="table table-hover table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('inputdoc.inputdoc_get_y'); altOrderType(); }}>ปี</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('inputdoc.inputdoc_no'); altOrderType(); }}>เลขหนังสือ</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('inputdoc.inputdoc_date'); altOrderType(); }}>ลงวันที่</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('office.office_name'); altOrderType(); }}>จาก</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('inputdoc.inputdoc_get_no'); altOrderType(); }}>เลขรับ</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('user.user_dispname'); altOrderType(); }}>เจ้าหน้าที่</button>
                                    </th>
                                    <th scope="col">
                                        <button className="btn btn-transparent p-0 m-0 btn-lg" onClick={() => { setOrderBy('section.section_name'); altOrderType(); }}></button>
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col"><div className="text-warning h5 p-0 m-0">หนังสือส่งออก</div></th>
                                    <th scope="col"><div className="text-danger h5 p-0 m-0">การติดตาม</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {indocDataWithCounts.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <tr className={(lastActionIndocId === data.inputdoc_id) ? 'border-2 table-active' : ''}>
                                            <td onClick={() => { handleShowOutdoc(data.inputdoc_id) }}>{startRows + index}</td>
                                            <td onClick={() => { handleShowOutdoc(data.inputdoc_id) }}>{data.inputdoc_get_y + 543}</td>
                                            <td onClick={() => { handleShowOutdoc(data.inputdoc_id) }}>{data.inputdoc_no}</td>
                                            <td onClick={() => { handleShowOutdoc(data.inputdoc_id) }}>{new Date(data.inputdoc_date).toLocaleDateString('th-TH', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}</td>
                                            <td onClick={() => { handleShowOutdoc(data.inputdoc_id) }}>{data.office_name}</td>
                                            <td onClick={() => { handleShowOutdoc(data.inputdoc_id) }}>{data.inputdoc_get_no}</td>
                                            <td className="text-secondary">{data.user_dispname}</td>
                                            <td className="text-secondary">{data.section_name}</td>
                                            <td>
                                                <button onClick={() => openModalEditIndoc(data.inputdoc_id)} className="btn bg-transparents"><i className="bi bi-pencil-square" style={{ fontSize: "1.1rem", color: "#22CC99" }}></i></button>
                                                {(new Date().toLocaleDateString() === new Date(data.inputdoc_mod_date).toLocaleDateString()) ? <i className="bi bi-check2-circle" style={{ fontSize: "1.0rem", color: "#AAFF99" }} title='อัปเดตวันนี้'></i> : ''}
                                            </td>
                                            <td onClick={() => { handleShowOutdoc(data.inputdoc_id) }}>
                                                {data.outdocList.length !== 0 ?
                                                    <div className="d-inline bg-warning p-1 m-0" style={{ fontSize: "1.0rem", color: "#000000" }}>
                                                        {data.outdocList?.length || ''}
                                                    </div>
                                                    : ''
                                                }
                                                {data.sentCount > 0 &&
                                                    <div className="d-inline bg-success text-light p-1 mx-1 ms-1" style={{ fontSize: "1.0rem", color: "#000000" }}>
                                                        {data.sentCount}
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                {data.trackCount > 0 &&
                                                    <div className="d-inline bg-danger text-light p-1 mx-1 ms-1" style={{ fontSize: "1.0rem", color: "#000000" }}>
                                                        {data.trackCount}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr id={`out_${data.inputdoc_id}`} hidden={!(data.inputdoc_id === lastActionIndocId)}>
                                            <td colSpan={2}></td>
                                            <td colSpan={6}>
                                                <div className="border border-warning rounded-2 p-0 m-0">
                                                    {data.outdocList.map((data, index) => (
                                                        <li className="mx-2 my-0 p-0" key={index}>
                                                            {`เลขที่ ${data.outputdoc_no} ลงวันที่ ${new Date(data.outputdoc_date).toLocaleDateString('th-TH', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                            })}  ส่งถึง `}
                                                            <div className="d-inline text-warning m-0 p-0">{`${data.office_name}`}</div>
                                                            <button onClick={() => openModalEditOutdoc(data.outputdoc_id)} className="btn bg-transparents ms-2"><i className="bi bi-pencil-square" style={{ fontSize: "1.0rem", color: "#22CC99" }}></i></button>
                                                            {(new Date().toLocaleDateString() === new Date(data.outputdoc_mod_date).toLocaleDateString()) ? <i className="bi bi-check2-circle me-2" style={{ fontSize: "1.0rem", color: "#AAFF99" }} title='อัปเดตวันนี้'></i> : ''}
                                                            {data.officer_id != null ? <div className="d-inline-block mx-1 my-0 p-1 text-warning rounded-pill" style={{ fontSize: '0.8rem', background: '#555' }}>ชื่อเจ้าหน้าที่</div> : ''}
                                                            {data.address_id != null ? <div className="d-inline-block mx-1 my-0 p-1 text-warning rounded-pill" style={{ fontSize: '0.8rem', background: '#555' }}>ที่อยุ่</div> : ''}
                                                            {data.email_id != null ? <div className="d-inline-block mx-1 my-0 p-1 text-warning rounded-pill" style={{ fontSize: '0.8rem', background: '#555' }}>อีเมล</div> : ''}
                                                            <div className="d-inline-block mx-0 my-0 p-0">
                                                                {data.email_id != null & data.senddoc_send_date == null ?
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        delay={{ hide: 450, show: 300 }}
                                                                        overlay={(props) => (
                                                                            <Tooltip {...props}>
                                                                                ส่งอีเมล
                                                                            </Tooltip>
                                                                        )}>
                                                                        <button type="button"
                                                                            onClick={() => {
                                                                                openModalSend(`ส่งเอกสาร '${data.outputdoc_no}' \n ออกไปทางอีเมล ?`);
                                                                                setSendOutdocId(data.outputdoc_id);
                                                                                setSendOfficeId(data.office_id);
                                                                                setSendEmailId(data.email_id);
                                                                            }}
                                                                            className="btn bg-transparents"><i className="bi bi-envelope-at-fill" style={{ fontSize: "1.9rem", color: "#00AAFF" }}></i>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                    : ''
                                                                }
                                                            </div>
                                                            {data.senddoc_send_date != null ? <div className="d-inline-block m-0 px-1 py-0 bg-success rounded-pill">ส่งแล้ว</div> : ''}
                                                            <div className="d-inline-block m-0 p-0">
                                                                {data.senddoc_send_date != null ?
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        delay={{ hide: 450, show: 300 }}
                                                                        overlay={(props) => (
                                                                            <Tooltip {...props}>
                                                                                ยกเลิกการส่งอีเมล
                                                                            </Tooltip>
                                                                        )}>
                                                                        <button type="button"
                                                                            onClick={() => {
                                                                                openModalUnSend(`ยกเลิกการส่งเอกสาร ?`);
                                                                                setUnSendOutdocId(data.outputdoc_id);
                                                                                setUnSendOfficeId(data.office_id);
                                                                                setUnSendEmailId(data.email_id);
                                                                            }}
                                                                            className="btn bg-transparents"><i className="bi bi-envelope-slash" style={{ fontSize: "1.8rem", color: "#FF3300" }}></i>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </li>
                                                    ))}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ hide: 450, show: 300 }}
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                เพิ่มเอกสารส่งออก
                                                            </Tooltip>
                                                        )}>
                                                        <button onClick={() => openModalOutdoc()} className="btn bg-transparents"><i className="bi bi-plus-circle-fill" style={{ fontSize: "1.2rem", color: "#FFAA00" }}></i></button>
                                                    </OverlayTrigger>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end">
                            <RcPagination
                                totalItems={totalItems}
                                itemsPerPage={itemsPerPage}
                                currentPage={currentPage}
                                onChange={handlePageChange}
                            />
                            <ItemsPerpageSelector />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default DocRec;