import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getToken } from '../utils/auth-client';

const host = 'https://api.dataxo.info';

function Signup() {
    const [username, setUsername] = useState('');
    const [dispName, setDispName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [sectionName, setSectionName] = useState('');
    const [roleName, setRoleName] = useState('');
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        const roleId = localStorage.getItem('roleId');
        // redirect to signin if not administrator 
        if (!token || roleId != '1') {
            navigate('/signin');
            return;
        }
    }, [])

    const userId = localStorage.getItem('userId');
    const [clientIP, setClientIP] = useState('');
    useEffect(() => {
        //service for get  client IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setClientIP(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    const [dataSection, setDataSection] = useState([]);
    useEffect(() => {
        const url = `${host}/api-data/section`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataSection(json));
    }, []);

    const [dataRole, setDataRole] = useState([]);
    useEffect(() => {
        const url = `${host}/api-user/role`;
        fetch(url, {
            method: 'GET',
            headers: {
                'x-access-token': token
            }
        })
            .then(res => res.json())
            .then(json => setDataRole(json));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            username,
            dispName,
            userEmail,
            password,
            sectionName,
            roleName,
            clientIP,
            userId
        };
        const url = `${host}/api-user/signup`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'x-access-token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
            });

            const json = await response.json();

            if (json.success) {
                openModal('การลงทะเบียนสำเร็จ', 1);
            } else {
                openModal('ลงทะเบียนไม่สำเร็จ: ' + json.error, 3);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModal = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        }
        if (modalType === 2) {
            classname = 'modal-content align-items-center justify-content-center bg-warning text-dark';
        }
        if (modalType === 3) {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalOpen} onExit={closeModal} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModal} className="btn bg-transparents"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                </div>
            </Modal>
        );
    }
    const roleOptions = dataRole.map((dataRole) => dataRole.userrole_name);
    const roleref = useRef(null);
    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <form style={{ width: '300px' }} onSubmit={handleSubmit} autoComplete="off">
                <h1 className="h3 mb-3 fw-normal">ลงทะเบียน</h1>
                <div className="form-floating my-1">
                    <input
                        type="text"
                        className="form-control"
                        id="user_dispname"
                        maxLength={45}
                        required
                        onChange={(e) => setDispName(e.target.value)}
                    />
                    <label htmlFor="user_dispname">ชื่อ</label>
                </div>
                <div className="form-floating my-1">
                    <Typeahead
                        onChange={(selected) => setSectionName(selected)}
                        onBlur={(e) => setSectionName(e.target.value.trim())}
                        options={dataSection.map((dataSection, index) => (dataSection.section_name))}
                        highlightOnlyResult
                        emptyLabel="ไม่เคยมีในฐานข้อมูล"
                        paginationText="ต่อไป..."
                        inputProps={{ required: true, className: "form-control pb-0 border-1" }}
                        id="section_name" name="section_name" value={sectionName} type="text" style={{ height: '60px' }}
                        maxLength="45" />
                    <label htmlFor="section_name" className="py-0 text-secondary">หน่วยงาน</label>
                </div>
                <div className="form-floating my-1">
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        maxLength={200}
                        required
                        onChange={(e) => {
                            const lowercaseValue = e.target.value.toLowerCase();
                            e.target.value = lowercaseValue;
                            setUsername(lowercaseValue);
                        }}
                    />
                    <label htmlFor="username">กำหนด Username</label>
                </div>
                <div className="form-floating my-1">
                    <input
                        type="email"
                        className="form-control"
                        id="user_email"
                        maxLength={200}
                        required
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <label htmlFor="user_email">อีเมล</label>
                </div>
                <div className="form-floating my-1">
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        maxLength={45}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="passwordInput">รหัสผ่าน</label>
                </div>
                <div className="form-floating my-1">
                    <Typeahead
                        ref={roleref}
                        onChange={(selected) => setRoleName(selected)}
                        onBlur={
                            (e) => {
                                const inputValue = e.target.value.trim();
                                const isValidRoleName = roleOptions.includes(inputValue);
                                if (!isValidRoleName) {
                                    roleref.current.clear();
                                    setRoleName('');
                                }
                            }
                        }
                        options={roleOptions}
                        highlightOnlyResult
                        emptyLabel="ไม่เคยมีในฐานข้อมูล"
                        paginationText="ต่อไป..."
                        inputProps={{ required: true, className: "form-control pb-0 border-1" }}
                        id="role_name" name="role_name" value={roleName} type="text" style={{ height: '60px' }}
                        maxLength="20" />
                    <label htmlFor="role_name" className="py-0 text-secondary">บทบาท</label>
                </div>
                <div className="form-floating my-3 d-flex justify-content-end">
                    <button className="btn btn-primary w-50 py-2" type="submit">
                        ลงทะเบียน
                    </button>
                </div>
            </form>
            <ModalInfo />
        </div>
    )
}

export default Signup;