import Page404 from './404.jsx'

function About() {
    return (
        <div className="About">
            <div className="d-flex align-items-center justify-content-center">
            <i className="bi bi-info-circle" style={{fontSize: "1.5rem", color: "#77DD11"}}></i>
                <h3 className="text-secondary">Devolope by JLapps</h3>
            </div>
            <div style={{position: 'relative', top:'0px',width:'100%'}}>
            <Page404/>
            </div>
        </div>
    )
}

export default About;
