import DashB from './dashb'

function AppHome() {
    return (
        <div className="AppHome">
            <div className="home">
                <DashB />
            </div>
        </div>
    )
}

export default AppHome;