import React, { useState, useEffect } from 'react';
import { setToken, getToken } from '../utils/auth-client';
import { userAgent } from '../utils/useragent';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const host = 'https://api.dataxo.info';

function Signin() {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (token) {
            navigate('/');
            return;
        }
    }, [])

    const [clientIP, setClientIP] = useState('');
    const [userAgentData, setUserAgentData] = useState();
    useEffect(() => {
        //service for get  client IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setClientIP(data.ip))
            .then(setUserAgentData(userAgent()))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    useEffect(() => {
        //get  client device data

    }, []);

    const [userInput, setUserInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            userInput,
            passwordInput,
            clientIP,
            userAgentData
        };
        const url = `${host}/api-user/signin`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });
            const json = await response.json();
            if (json.success) {
                if (json.token) {
                    setToken(json.token);
                    localStorage.setItem('userId', json.user.id);
                    localStorage.setItem('dispName', json.user.dispName);
                    localStorage.setItem('roleId', json.user.roleId);
                    localStorage.setItem('sectionId', json.user.sectionId);
                    openModal('ล็อกอินสำเร็จ', 1);
                } else {
                    openModal('ไม่ได้รับอนุญาตอย่างถูกต้อง', 3)
                }
            } else {
                openModal('ล็อกอินไม่สำเร็จ: ' + json.error, 3);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModal = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        if (modalType === 1) {
            window.location.replace('/');
        }
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        }
        if (modalType === 2) {
            classname = 'modal-content align-items-center justify-content-center bg-warning text-dark';
        }
        if (modalType === 3) {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalOpen} onExit={closeModal} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModal} className="btn bg-transparents"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                </div>
            </Modal>
        );
    }

    return (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <form style={{ width: '300px' }} onSubmit={handleSubmit}>
                <h1 className="h3 mb-3 fw-normal">ล็อกอินเข้าสู่ระบบ</h1>
                <div className="form-floating my-1">
                    <input
                        type="text"
                        className="form-control"
                        id="userInput"
                        name="userInput"
                        maxLength={200}
                        required
                        onChange={(e) => setUserInput(e.target.value)} />
                    <label htmlFor="userInput">อีเมล หรือ Username</label>
                </div>
                <div className="form-floating my-1">
                    <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        name="passwordInput"
                        maxLength={45}
                        required
                        onChange={(e) => setPasswordInput(e.target.value)} />
                    <label htmlFor="passwordInput">รหัสผ่าน</label>
                </div>
                <button className="btn btn-primary w-100 py-2" type="submit">เข้าสู่ระบบ</button>
            </form>
            <ModalInfo />
        </div>
    )
}

export default Signin;