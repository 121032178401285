import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Layout from './routes/layout';
import AppHome from './routes/home';
import DocRec from './routes/docrec';
import OutDoc from './routes/outdoc';
import About from './routes/about';
import Signin from './routes/signin';
import UserRec from './routes/userrec';
import Page404 from './routes/404';
import PrivateRoute from './routes/privateroute';

function App() {
  return (
    <div className="App">
      <div className="Container m-3">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<AppHome />} />
              <Route path="/doc/rec" element={<PrivateRoute />}>
                <Route path="/doc/rec" element={<DocRec />} />
              </Route>
              <Route path="/outdoc" element={<OutDoc />} />
              <Route path="/about" element={<About />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/userrec" element={<UserRec />} />
              <Route path="*" element={<Page404 />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  )
}

export default App;