import React, { useState, useEffect, useCallback } from 'react';
import { getToken, removeToken } from '../utils/auth-client';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useDropzone } from 'react-dropzone';

const host = 'https://api.dataxo.info';

function ImDoc() {
    const navigate = useNavigate();
    //verify token 
    const token = getToken();
    useEffect(() => {
        if (!token) {
            navigate('/signin');
            return;
        }
    }, [])

    const handleUnauthResponse = (response) => {
        if (response && (response.status === 401 || response.status === 403)) {
            removeToken();
            navigate('/signin');
            return;
        } else {
            window.location.reload();
        }
    }

    const userId = localStorage.getItem('userId');
    const [clientIP, setClientIP] = useState('');
    useEffect(() => {
        //service for get  client IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setClientIP(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    const [inputFiles, setInputFiles] = useState('');
    const [files, setFiles] = useState([]);
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        handleFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        }
    });

    const handleFiles = (selectedFiles) => {
        if (selectedFiles && selectedFiles.length > 0) {
            const xlsxFiles = selectedFiles.filter(file => file.name.toLowerCase().endsWith('.xlsx'));
            if (xlsxFiles.length > 0) {
                setFiles(xlsxFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
                setInputFiles(xlsxFiles[0]);
            } else {
                openModal('กรุณาเลือกไฟล์ XLSX เท่านั้น', 2);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!inputFiles) {
            openModal('กรุณาเลือกไฟล์ XLSX ก่อนทำการนำเข้า', 2);
            return;
        }
        const url = `${host}/api-inputfile/imindoc`;
        const formData = new FormData();
        formData.append('clientIP', clientIP);
        formData.append('userId', userId);
        files.forEach(file => {
            formData.append('files', file);
        });
        try {
            toggleUploadingState(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'x-access-token': token
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                if (data.totalRows === data.totalAdded) {
                    openModal(`นำเข้าข้อมูลทั้งหมดสำเร็จแล้ว ${data.totalAdded} รายการ`, 1);
                } else if (data.totalRows === data.totalIgnored) {
                    openModal('ไม่มีการนำเข้าใหม่ เนื่องจากมีข้อมูลเอกสารนี้อยู่แล้ว', 3);
                } else if (data.totalRows > data.totalAdded & data.totalAdded > 0) {
                    openModal(`นำเข้าข้อมูลใหม่ ${data.totalAdded} รายการ ไม่นำเข้า ${data.totalIgnored} รายการ เนื่องจากมีอยู่แล้ว `, 2);
                } else {
                    openModal('เกิดข้อผิดพลาดในการนำเข้าข้อมูล รูปแบบไฟล์อาจไม่ถูกต้อง', 3);
                }
            } else {
                handleUnauthResponse(response);
                throw new Error('Network response was not ok');
            }
        } catch (err) {
            console.error('Error:', err);
            openModal('เกิดข้อผิดพลาดในการนำเข้าข้อมูลของระบบ', 2);
            handleUnauthResponse(err.response);
        } finally {
            toggleUploadingState(false);
        }
    }

    const [isUploading, setIsUploading] = useState(false);

    const toggleUploadingState = (state) => {
        setIsUploading(state);
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);
    const [modalText, setModalText] = useState('');

    const openModal = (text, type) => {
        setModalText(text);
        setModalType(type);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const ModalInfo = () => {
        let classname;
        if (modalType === 1) {
            classname = 'modal-content align-items-center justify-content-center bg-success';
        }
        if (modalType === 2) {
            classname = 'modal-content align-items-center justify-content-center bg-warning text-dark';
        }
        if (modalType === 3) {
            classname = 'modal-content align-items-center justify-content-center bg-danger';
        }
        return (
            <Modal show={isModalOpen} onExit={closeModal} centered>
                <div className={classname}>
                    <p className="fs-5 p-3">{modalText}</p>
                    <button onClick={closeModal} className="btn bg-transparents"><i className="bi bi-x-circle-fill" style={{ fontSize: "1.7rem", color: "#CCCCCC" }}></i></button>
                </div>
            </Modal>
        );
    }

    return (
        <div className="align-items-center justify-content-center w-100 p-2">
            <div {...getRootProps()} className={`dropzone p-5 border border-dashed ${isDragActive ? 'border-primary border border-3' : 'border-secondary'}`}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p className="text-center">ปล่อยไฟล์ XLSX ที่นี่ ...</p> :
                        <p className="text-center">ลากและวางไฟล์ XLSX ที่นี่ หรือคลิกเพื่อเลือกไฟล์</p>
                }
            </div>
            <form onSubmit={handleSubmit} method="post" autoComplete="off">
                <div className="mt-3">
                    <h6>ไฟล์ที่นำเข้า:</h6>
                    <ul>
                        {files.map(file => (
                            <li key={file.name}>
                                {file.name} - {file.size} bytes
                            </li>
                        ))}
                    </ul>
                </div>
                {inputFiles && (
                    <div className="input-group align-items-center justify-content-center">
                        <button className="btn btn-primary pt-2 py-2 my-2" type="submit" disabled={isUploading || !inputFiles}>
                            {isUploading ? 'กำลังอัปโหลดไฟล์...' : 'นำเข้า'}
                        </button>
                    </div>
                )}
            </form>
            <ModalInfo />
        </div>
    );
}

export default ImDoc;